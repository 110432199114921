import React from "react"

export const Ternary = ({
  condition,
  children,
}: {
  condition: boolean,
  children: React.ReactNode,
}) => {
  if (condition) {
    return (
      <>
        {React.Children.toArray(children).at(0)}
      </>
    );
  }

  return (
    <>
      {React.Children.toArray(children).at(1)}
    </>
  );
}
