import { Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../../components/modal/modal";
import { translations } from "../../../../../core/constants/translations";
import { useLangParam } from "../../../../../core/hooks";
import { addCapabilitiesThunk } from "../../../../../store/capabilities/thunks";
import { selectCommerceId } from "../../../../../store/commerce/selectors";
import { addResourceThunk } from "../../../../../store/resource/thunks";
import { addResourceTimeframesThunk } from "../../../../../store/resource_timeframe/thunks";
import { selectServices } from "../../../../../store/service/selectors";
import { AppDispatch } from "../../../../../store/store";
import { selectTimeframes } from "../../../../../store/timeframe/selectors";
import { ServiceAssociationItem } from "../../../../onboarding/resource-stage/resource-screen/service-association-item";

export const AddResourceModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam()
  const translation = translations["Onboarding"]["Resources"]

  const [loading, setLoading] = useState(false);

  const services = useSelector(selectServices);
  const commerceId = useSelector(selectCommerceId);
  const timeframes = useSelector(selectTimeframes);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [choosenServices, setChoosenServices] = useState<string[]>([]);

  return (
    <Modal
      title={translations["Onboarding"]["New resource"]["Add resource"][lang]}
      onClose={onClose}
    >
      <div className="w-full p-8 flex flex-col gap-2">
        <TextField
          label={translation["Name"][lang] + " *"}
          size="small"
          sx={{
            width: "100%",
          }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <TextField
          multiline
          label={translation["Description Title"][lang] + " *"}
          size="small"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />

        <p className="text-xs text-gray-600 pt-3">
          {translations["Onboarding"]["New resource"]["Service association"][lang]}
        </p>

        <div className="flex flex-col">
          {services.map((service, i, list) => {
            const isChoosen = choosenServices.includes(service.id);
            return (
              <ServiceAssociationItem
                service={service}
                index={i}
                last={list.length - 1 === i}
                isChecked={isChoosen}
                onClick={() => {
                  if (!isChoosen) {
                    setChoosenServices([...choosenServices, service.id]);
                  } else {
                    setChoosenServices(
                      choosenServices.filter((s) => s !== service.id),
                    );
                  }
                }}
              />
            );
          })}
        </div>

        <p className="text-xs text-gray-600 pb-3">
          * {translations["Onboarding"]["New resource"]["fields"][lang]}
        </p>

        <Button
          variant="contained"
          sx={{
            width: "100%",
            background: "oklch(30% 0 0)",
            "&:hover": {
              background: "oklch(40% 0 0)",
            },
          }}
          disabled={!name || !description || loading}
          onClick={() => {
            setLoading(true);

            commerceId &&
              dispatch(
                addResourceThunk({
                  name,
                  description,
                  commerce_id: commerceId,
                }),
              )
                .unwrap()
                .then(async ({ id }) => {
                  await Promise.all(
                    timeframes.map(async ({ day, start, duration }) => {
                      return await dispatch(
                        addResourceTimeframesThunk({
                          day,
                          start,
                          duration,
                          resource_id: id,
                        }),
                      );
                    })
                  );

                  await Promise.all(
                    choosenServices.map(async (s) => {
                      return await dispatch(
                        addCapabilitiesThunk({
                          resource_id: id,
                          service_id: s,
                          commerce_id: commerceId,
                        }),
                      );
                    })
                  )

                  setLoading(false);
                  onClose();
                });
          }}
        >
          {loading ?
            <CircularProgress
              size={25}
              sx={{
                color: "rgb(192 132 252)",
              }}
            />
          : translations["Onboarding"]["New resource"]["Save"][lang]}
        </Button>
      </div>
    </Modal>
  );
};
