import { createSelector } from "@reduxjs/toolkit";
import { Entity } from "../../core/repository/repository";
import { selectState } from "../store";

export const selectCustomersState = createSelector(
  [selectState],
  (state) => state.customer,
);

export const selectCustomers = createSelector(
  [selectCustomersState],
  (state) => state.value,
);

export const selectCustomerRepository = createSelector(
  [selectCustomersState],
  (state) => new Entity(state.value),
);

export const selectCustomersStatus = createSelector(
  [selectCustomersState],
  (state) => state.status,
);

export const selectCurrentCustomerId = createSelector(
  [selectCustomersState],
  (state) => state.current
);

export const selectCustomerError = createSelector(
  [selectCustomersState],
  (state) => state.error
);

export const selectCurrentCustomer = createSelector(
  [selectCustomersState],
  (state) => state.value.find(({ id }) => id === state.current)
);
