import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { createContext, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WeekScheduleTable } from "../../components/week-schedule/week-schedule-table";
import { ResourceInterface } from "../../store/resource/interfaces";
import { selectResources } from "../../store/resource/selectors";
import { selectResourceTimeframes } from "../../store/resource_timeframe/selectors";
import { addResourceTimeframesThunk, removeResourceTimeframesThunk, updateResourceTimeframesThunk } from "../../store/resource_timeframe/thunks";
import { AppDispatch } from "../../store/store";

const ResourceSchedulePageMobileContext = createContext<{
  setCurrentResourceId: (id: string) => void,
}>({
  setCurrentResourceId: () => {},
});

export const ResourceSchedulePageMobile = () => {
  const [currentResourceId, setCurrentResourceId] = useState<string>();

  return (
    <ResourceSchedulePageMobileContext.Provider value={{ setCurrentResourceId }}>
      <div className={`gap-5 pt-5 lg:gap-10 lg:pt-10 flex flex-col h-[calc(100%-60px)]`}>
        <div className={`flex items-center h-16 w-full bg-white/50 rounded-2xl p-3 lg:p-5`}>
          {!!currentResourceId && (
            <div
              className="px-4"
              onClick={() => {
                setCurrentResourceId(undefined);
              }}
            >
              <ChevronLeft />
            </div>
          )}

          <p className={`text-slate-700 text-xl lg:text-2xl font-medium`}>
            Resources Schedule
          </p>
        </div>

        {!!currentResourceId ? <ResourceTimeframes resourceId={currentResourceId} /> : <ResourceList />}
      </div>
    </ResourceSchedulePageMobileContext.Provider>
  );
}

const ResourceTimeframes = ({
  resourceId,
}: {
  resourceId: string,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const resources = useSelector(selectResources);
  const resource = resources.find(({ id }) => id === resourceId);
  const resourcesTimeframes = useSelector(selectResourceTimeframes);

  if (!resource) return null;

  const resourceTimeframes = resourcesTimeframes.filter(({ resource_id }) => {
    return resource_id === resource.id;
  })

  return (
    <div className={`
      flex flex-col justify-center w-full bg-white/50 rounded-2xl pb-5 pt-2
      h-auto max-h-full
    `}>
      <p className={`text-slate-700 text-xl lg:text-2xl px-5 pt-2 font-medium`}>
        Edit {resource.name} schedule
      </p>

      <WeekScheduleTable
        timeframes={resourcesTimeframes}
        onAddTimeframe={(t) => {
          resourceTimeframes.forEach((resource) => {
            dispatch(
              addResourceTimeframesThunk({
                ...t,
                resource_id: resource.id,
              }),
            );
          });
        }}
        onRemoveTimeframes={(ids) => {
          dispatch(removeResourceTimeframesThunk(ids));
        }}
        onUpdateTimeframes={(t) => {
          dispatch(updateResourceTimeframesThunk(t));
        }}
      />
    </div>
  );
}

const ResourceList = () => {
  const resources = useSelector(selectResources);

  return (
    <div className={`
      flex flex-col justify-center w-full bg-white/50 rounded-2xl pb-5 pt-2
      h-auto max-h-full
    `}>
      {resources.map((resource, i, list) => {
        return (
          <ResourceItem 
            resource={resource}
            index={i}
            list={list}
          />
        );
      })}
    </div>
  );
}

const ResourceItem = ({
  resource,
  index,
  list,
}: {
  resource: ResourceInterface,
  index: number,
  list: ResourceInterface[],
}) => {
  const ctx = useContext(ResourceSchedulePageMobileContext);

  const resourceTimeframes = useSelector(selectResourceTimeframes);
  let duration = resourceTimeframes.filter(({ resource_id }) => {
    return resource_id === resource.id;
  }).reduce((acc, { duration }) => {
    return acc + (duration || 0);
  }, 0)

  duration = Math.floor(duration / 60);

  return (
    <div
      className={`
        grid grid-cols-[2fr_1fr_1fr] px-5 py-3
        border-t border-t-1 ${list.length - 1 === index ? "border-b border-b-1" : ""}
      `}
      onClick={() => {
        ctx.setCurrentResourceId(resource.id);
      }}
    >
      <p>
        {resource.name || `Resource #${index}`}
      </p>

      <p className="ml-auto">
        {duration}h
      </p>

      <div className="ml-auto">
        <ChevronRight />
      </div>
    </div>
  );
}
