import { CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommerceSelector } from "../../../../../components/commerce-selector/commerce-selector";
import { WeekSchedule } from "../../../../../components/week-schedule/week-schedule";
import { translations } from "../../../../../core/constants/translations";
import { useLangParam } from "../../../../../core/hooks";
import { CommerceInterface } from "../../../../../store/commerce/interfaces";
import {
  selectCommerce,
  selectCommerceId,
} from "../../../../../store/commerce/selectors";
import { setCommerceThunk } from "../../../../../store/commerce/thunks";
import { selectBusinessTypes } from "../../../../../store/constants/business-type/selectors";
import { IndustryTypeInterface } from "../../../../../store/constants/industry-type/interfaces";
import { selectIndustryTypes, selectIndustryTypesRepository } from "../../../../../store/constants/industry-type/selectors";
import { AppDispatch } from "../../../../../store/store";
import { selectTimeframes } from "../../../../../store/timeframe/selectors";
import {
  addTimeframesThunk,
  removeTimeframesThunk,
  updateTimeframesThunk,
} from "../../../../../store/timeframe/thunks";
import { selectUser } from "../../../../../store/user/selectors";

export const CommerceConfig = ({
  onClose,
}: {
  onClose: () => void,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Demo"]["Configuration"];

  const [loading, setLoading] = useState(false);

  const commerceId = useSelector(selectCommerceId);

  const storeCommerce = useSelector(selectCommerce);
  const [commerce, setCommerce] = useState<CommerceInterface>({});
  const industryTypes = useSelector(selectIndustryTypes);
  const industryTypesRepository = useSelector(selectIndustryTypesRepository);
  const businessTypes = useSelector(selectBusinessTypes);
  const user = useSelector(selectUser);

  const timeframes = useSelector(selectTimeframes);

  const allowedBusinessTypes = businessTypes.filter(
    ({ industry_id }) =>
      industryTypes.find((it) => it.name === commerce.industry_type)?.id ===
      industry_id,
  );

  const onSubmit = async () => {
    commerce &&
      user?.id &&
      await dispatch(
        setCommerceThunk({
          userId: user?.id,
          ...commerce,
        }),
      );
  };

  const langKey = (lang === "en" ? "name" : lang) as keyof IndustryTypeInterface

  useEffect(() => {
    storeCommerce && setCommerce(storeCommerce);
  }, [storeCommerce]);

  return (
    <div className="flex flex-col p-3 gap-2">
      <TextField
        size="small"
        placeholder={translation["Name"][lang]}
        value={commerce.name}
        onChange={(e) => {
          setCommerce({
            ...commerce,
            name: e.target.value,
          });
        }}
      />

      <CommerceSelector
        placeholder={translation["Industry"][lang]}
        options={industryTypesRepository.over?.[langKey].sort() || []}
        value={industryTypesRepository.findOne?.name(commerce.industry_type)?.[langKey]}
        setValue={(value) => {
          const name = industryTypesRepository.findOne?.[langKey](value)?.name

          setCommerce({
            ...commerce,
            industry_type: name,
            business_type: undefined,
          });
        }}
      />

      <CommerceSelector
        disabled={allowedBusinessTypes.length === 0}
        placeholder={translation["Business"][lang]}
        options={allowedBusinessTypes.map((bt) => bt[langKey] as string).sort()}
        value={allowedBusinessTypes.find((bt) => bt.name === commerce.business_type)?.[langKey] as string}
        setValue={(value) => {
          const name = allowedBusinessTypes.find((bt) => bt[langKey] === value)?.name

          setCommerce({
            ...commerce,
            business_type: name,
          });
        }}
      />

      <WeekSchedule
        timeframes={timeframes}
        onAddTimeframes={(t) => {
          commerceId &&
            dispatch(
              addTimeframesThunk({
                ...t,
                commerce_id: commerceId,
              }),
            );
        }}
        onRemoveTimeframes={(ids) => {
          dispatch(removeTimeframesThunk(ids));
        }}
        onUpdateTimeframes={(t) => {
          dispatch(updateTimeframesThunk(t));
        }}
      />

      <p
        className={`text-white cursor-pointer w-16 flex items-center justify-center ml-auto rounded-full ${loading ? "bg-gray-300 py-2 " : "hover:bg-indigo-400 bg-indigo-500 py-1"}`}
        onClick={async () => {
          if (loading) return;

          setLoading(true);
          await onSubmit();
          onClose();
          setLoading(false);
        }}
      >
        {
          loading ? 
            <CircularProgress
              size={18}
              sx={{
                color: "rgb(192 132 252)",
              }}
            />
          : translation["Button"][lang]
        }
      </p>
    </div>
  );
};
