import { CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmDeleteModal } from "../../../../../components/confirm-delete-modal/confirm-delete-modal";
import { CurrencySelector } from "../../../../../components/currency-selector/currency-selector";
import { translations } from "../../../../../core/constants/translations";
import { useLangParam } from "../../../../../core/hooks";
import { selectCapabilities } from "../../../../../store/capabilities/selectors";
import { removeCapabilityThunk } from "../../../../../store/capabilities/thunks";
import { selectCommerceId } from "../../../../../store/commerce/selectors";
import { ServiceInterface } from "../../../../../store/service/interfaces";
import { selectServices } from "../../../../../store/service/selectors";
import {
  getServicesThunk,
  removeServiceThunk,
  updateServiceThunk,
} from "../../../../../store/service/thunks";
import { AppDispatch } from "../../../../../store/store";
import { AddServiceModal } from "./add-service-modal";
import { ColorDropdown } from "./color-dropdown";

export const ServicesAndPrices = () => {
  const dispatch = useDispatch<AppDispatch>();
  const services = useSelector(selectServices);
  const lang = useLangParam();

  const commerce_id = useSelector(selectCommerceId);
  useEffect(() => {
    commerce_id && dispatch(getServicesThunk(commerce_id));
  }, [dispatch, commerce_id]);

  const [choosenService, setChoosenService] = useState<string>();

  const [openAddServiceModal, setOpenAddServiceModal] = useState(false);

  return (
    <div>
      {services.map((service, i) => {
        return (
          <ServiceItem
            key={i}
            code={service.name?.slice(0, 2).toUpperCase()}
            index={i}
            isOpen={choosenService === service.id}
            setIsOpen={() => {
              if (choosenService === service.id) {
                setChoosenService(undefined);
              } else {
                setChoosenService(service.id);
              }
            }}
            onClose={() => {
              setChoosenService(undefined);
            }}
            {...service}
          />
        );
      })}

      {openAddServiceModal && (
        <AddServiceModal
          onClose={() => {
            setOpenAddServiceModal(false);
          }}
        />
      )}

      <p
        className="text-sm text-indigo-500 p-3 cursor-pointer hover:text-indigo-400"
        onClick={() => {
          setOpenAddServiceModal(true);
        }}
      >
        + {translations["Onboarding"]["New service"]["Add service"][lang]}
      </p>
    </div>
  );
};

const ServiceItem = ({
  code,
  index,
  setIsOpen,
  isOpen,
  onClose,
  ...service
}: {
  code?: string;
  index: number;
  isOpen: boolean;
  setIsOpen: () => void;
  onClose: () => void,
} & ServiceInterface) => {
  const [openColorPalette, setOpenColorPalette] = useState(false);

  const border = "border-b border-gray-300";

  return (
    <div
      className={`px-3 hover:bg-indigo-50 cursor-pointer gap-6 items-start flex`}
    >
      <div className={`${border} w-full`}>
        <div
          className="flex py-4 justify-between"
          onClick={() => {
            setIsOpen();
            setOpenColorPalette(false);
          }}
        >
          <p className="text-lg font-medium w-64">
            {service.name || `Service #${index + 1}`}
          </p>

          <div className="flex flex-col gap-3">
            <p className="text-xs">{service.duration} min</p>
            <p className="text-xs">{service.price} {service.currency}</p>
          </div>

          <div>
            {openColorPalette && (
              <ColorDropdown
                onClose={() => {
                  setOpenColorPalette(false);
                }}
                service={service}
              />
            )}

            <p
              className={`${service.color} text-xs py-1 rounded-full w-12 text-gray-800 text-center cursor-pointer`}
              onClick={(e) => {
                e.stopPropagation();
                isOpen && setOpenColorPalette((v) => !v);
              }}
            >
              {code}
            </p>
          </div>
        </div>

        {isOpen && 
          <ServiceDropdown
            storeService={service}
            onClose={onClose}
          />
        }
      </div>
    </div>
  );
};

const ServiceDropdown = ({ storeService, onClose }: { storeService: ServiceInterface, onClose: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();
  const translation = translations["Onboarding"]["New service"];

  const commerce_id = useSelector(selectCommerceId);
  const [loading, setLoading] = useState(false);

  const [service, setService] = useState<ServiceInterface>();

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const capabilities = useSelector(selectCapabilities).filter((capability) => {
    return capability.service_id === storeService.id;
  });

  const disabled = (service?.price || 0) < 0 || (service?.duration || 0) < 0

  useEffect(() => {
    setService(storeService);
  }, [storeService]);

  return (
    <div className="flex gap-2 py-4">
      <div className="flex flex-col gap-2 w-full">
        <TextField
          label={translation["Name"][lang]}
          size="small"
          value={service?.name}
          onChange={({ target: { value } }) => {
            commerce_id &&
              setService({
                ...service,
                id: storeService.id,
                commerce_id,
                name: value,
              });
          }}
        />

        <TextField
          multiline
          label={translation["Description Title"][lang]}
          size="small"
          value={service?.description}
          onChange={({ target: { value } }) => {
            commerce_id &&
              setService({
                ...service,
                id: storeService.id,
                commerce_id,
                description: value,
              });
          }}
        />

        <DurationTextField
          value={service?.duration}
          onChange={(value) => {
            commerce_id &&
              setService({
                ...service,
                id: storeService.id,
                commerce_id,
                duration: value,
              });
          }}
        />

        <TextField
          label={translation["Price"][lang]}
          type="number"
          size="small"
          value={service?.price}
          InputProps={{
            inputProps: {
              min: 0,
            }
          }}
          onKeyPress={(event) => {
            if (event.code === "Minus")
              event.preventDefault();
          }}
          onChange={({ target: { value } }) => {
            commerce_id &&
              setService({
                ...service,
                id: storeService.id,
                commerce_id,
                price: +value,
              });
          }}
        />

        <CurrencySelector
          placeholder={translation["Currency"][lang]}
          currency={service?.currency}
          setCurrency={(value) => {
            commerce_id &&
              setService({
                ...service,
                id: storeService.id,
                commerce_id,
                currency: value,
              });
          }}  
        />

        <div className="flex items-center justify-end gap-3">
          <p
            className={`
              text-white cursor-pointer mt-2 border-2
              rounded-full font-medium flex items-center justify-center min-w-16 px-3
              ${loading || disabled ? "bg-gray-300 border-gray-300 py-2" : "bg-indigo-500 hover:bg-indigo-400 hover:border-indigo-400 border-indigo-500 py-1"}
              `}
            onClick={async () => {
              setLoading(true);

              await dispatch(
                updateServiceThunk({
                  id: storeService.id,
                  ...service,
                }),
              );

              onClose();
              setLoading(false);
            }}
          >
            {loading ? 
              <CircularProgress
                size={18}
                sx={{
                  color: "rgb(192 132 252)",
                }}
              />
            : translation["Save"][lang]}
          </p>

          {openConfirmDelete && 
            <ConfirmDeleteModal
              title="Confirm service delete"
              onClose={() => {
                setOpenConfirmDelete(false);
              }}
              onDelete={async () => {
                await Promise.all(
                  capabilities.map(async ({ id }) => {
                    await dispatch(removeCapabilityThunk(id));
                  })
                );

                await dispatch(removeServiceThunk(storeService.id));
              }}
            />
          }

          <p
            className="text-indigo-500 cursor-pointer mt-2 px-4 py-1 border-2 border-indigo-500 hover:border-indigo-400 hover:bg-gray-100 rounded-full font-medium"
            onClick={async () => {
              setOpenConfirmDelete(true);
            }}
          >
            {translation["Delete"][lang]}
          </p>
        </div>
      </div>
    </div>
  );
};

const DurationTextField = ({
  value,
  onChange,
}: {
  value?: number;
  onChange: (v: number) => void;
}) => {
  const lang = useLangParam();
  const translation = translations["Onboarding"]["New service"];

  const [hour, setHour] = useState<number>(Math.floor((value ?? 0) / 60));
  const [minute, setMinute] = useState<number>((value ?? 0) % 60);

  useEffect(() => {
    if (value) {
      setHour(Math.floor(value / 60));
      setMinute(value % 60);
    }
  }, [value]);

  return (
    <div className="flex gap-2">
      <TextField
        label={translation["Hours"][lang]}
        type="number"
        size="small"
        value={hour}
        InputProps={{
          inputProps: {
            min: 0,
          }
        }}
        onKeyPress={(event) => {
          if (event.code === "Minus")
            event.preventDefault();
        }}
        onChange={(e) => {
          const v = +e.target.value;
          setHour(() => {
            onChange(v * 60 + minute);
            return v;
          });
        }}
      />

      <TextField
        label={translation["Minutes"][lang]}
        type="number"
        size="small"
        value={minute}
        InputProps={{
          inputProps: {
            min: 0,
          }
        }}
        onKeyPress={(event) => {
          if (event.code === "Minus")
            event.preventDefault();
        }}
        onChange={(e) => {
          const v = +e.target.value;
          setMinute(() => {
            onChange(hour * 60 + v);
            return v;
          });
        }}
      />
    </div>
  );
};
