import { ChevronLeft } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlatformButton } from "../../../components/buttons/button";
import { countries } from "../../../core/constants/countries";
import { serviceColors } from "../../../core/constants/service-colors";
import { List } from "../../../core/repository/list";
import { selectCapabilityRepository } from "../../../store/capabilities/selectors";
import { addCapabilitiesThunk, removeCapabilityThunk } from "../../../store/capabilities/thunks";
import { selectCommerce, selectCommerceId } from "../../../store/commerce/selectors";
import { selectResourceRepository } from "../../../store/resource/selectors";
import { ServiceInterface } from "../../../store/service/interfaces";
import { selectServices } from "../../../store/service/selectors";
import { addServiceThunk, updateServiceThunk } from "../../../store/service/thunks";
import { AppDispatch } from "../../../store/store";
import { BasicInformation } from "./basic-information";
import { ChooseResource } from "./choose-resource";
import { ConfirmServiceDeletionModal } from "./confirm-deletion.modal";
import { PricesAndDuration } from "./prices-and-duration";

export const EditService = ({
  createService,
  serviceId,
  onBack,
}: {
  createService?: boolean,
  serviceId?: string,
  onBack: () => void,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [showErrors, setShowErrors] = useState(false);

  const commerceId = useSelector(selectCommerceId);
  const commerce = useSelector(selectCommerce);

  const services = useSelector(selectServices);
  const storeService = services.find(({ id }) => serviceId === id)
  const countryCurrency = countries.find(({ name }) => {
    return name === commerce?.country;
  })?.currencyCode;

  const [service, setService] = useState<Omit<ServiceInterface, "id"> & { id?: string }>(storeService || {
    name: "",
    color: "",
    price: 0,
    details: "",
    currency: countryCurrency,
    duration: 0,
    commerce_id: commerceId!,
    description: "",
  });

  const resources = useSelector(selectResourceRepository);
  const capabilities = useSelector(selectCapabilityRepository);
  const serviceCapabilities = capabilities.include?.service_id(serviceId || "");

  const [currentCapabilities, setCurrentCapabilities] = useState(new List(serviceCapabilities?.over?.resource_id || []));
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = useState(false);

  const notComplete = !(service.description && service.duration && service.currency
                        && service.price && service.name && service.duration > 0 && service.price > 0)

  return (
    <div className={`gap-5 pt-5 lg:gap-10 lg:pt-10 flex flex-col h-[calc(100%-60px)]`}>
      <div className={`flex items-center h-16 w-full bg-white/50 rounded-2xl p-3 lg:p-5`}>
        <div className="flex items-center gap-3">
          <div
            className="w-[25px] lg:w-[25px] h-[25px] lg:h-[25px] hover:bg-gray-300/50 cursor-pointer rounded-full"
            onClick={onBack}
          >
            <ChevronLeft
              style={{
                width: "100%",
                height: "100%",
                color: "rgb(51 65 85)",
              }}
            />
        </div>

          <p className={`text-slate-700 text-xl lg:text-2xl font-medium`}>
            {createService ? "Add Service" : "Edit Service"}
          </p>
        </div>

        <div className={`flex gap-2 lg:gap-4 items-center ml-auto`}>
          <PlatformButton
            className={`${showErrors && notComplete ? "bg-gray-300" : "bg-indigo-500"} hover:bg-indigo-400 w-20`}
            title="Save"
            onClick={async () => {
              if (notComplete) {
                setShowErrors(true);
                return;
              }

              if (createService) {
                const { id } = await dispatch(addServiceThunk({
                  ...service,
                  color: serviceColors[
                    Math.floor(Math.random() * serviceColors.length)
                  ],
                })).unwrap()

                await Promise.all([
                  currentCapabilities.fmap(async (resource_id) => {
                    commerceId && await dispatch(addCapabilitiesThunk({
                      resource_id,
                      service_id: id,
                      commerce_id: commerceId,
                    }));
                  })
                ])

                onBack();
                return;
              }

              await Promise.all([
                ...(service ? [dispatch(updateServiceThunk({
                  ...service,
                  id: service.id!,
                }))] : []),
                capabilities.exclude?.resource_id(...currentCapabilities.data).fmap(async ({ id }) => {
                  await dispatch(removeCapabilityThunk(id));
                })
              ])

              await Promise.all(
                currentCapabilities.fmap(async (resource_id) => {
                  commerceId && await dispatch(addCapabilitiesThunk({
                    resource_id,
                    service_id: serviceId!,
                    commerce_id: commerceId,
                  }));
                })
              )

              onBack();
            }}
          />

          {openConfirmDeletionModal && !createService &&
            <ConfirmServiceDeletionModal
              serviceId={serviceId || ""}
              onClose={() => {
                setOpenConfirmDeletionModal(false);
              }}
              onDelete={onBack}
            />
          }

          {!createService && 
            <PlatformButton
              title="Delete"
              className="bg-red-500 hover:bg-red-400 w-20"
              onClick={async () => {
                setOpenConfirmDeletionModal(true);
              }}
            />
          }
        </div>
      </div>

      <div className={`gap-5 lg:gap-10 flex flex-col overflow-y-auto h-full`}>
        <div className={`grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-10`}>
          <BasicInformation
            showErrors={showErrors}
            service={service}
            onChange={setService}
          />

          <PricesAndDuration
            showErrors={showErrors}
            service={service}
            onChange={setService}
          />
        </div>

        <ChooseResource
          resources={resources}
          capabilities={currentCapabilities}
          onChange={(id) => {
            if (currentCapabilities.includes(id)) {
              setCurrentCapabilities(currentCapabilities.exclude(id))
            } else {
              setCurrentCapabilities(currentCapabilities.append(id))
            }
          }}
        />
      </div>
    </div>
  );
}
