import { Id } from "./interfaces";

export class Entity<T extends Id> {
  constructor(
    public data: T[]
  ) {}

  get length() {
    return this.data.length;
  }

  fmap<K>(f: (v: T, i: number) => K): K[] {
    return this.data.map(f);
  }

  map<K extends Id>(f: (v: T) => K): Entity<K> {
    return new Entity<K>(this.fmap(f));
  }

  filter(f: (v: T) => boolean): Entity<T> {
    return new Entity<T>(this.data.filter(f));
  }

  fold<K>(f: (acc: K, current: T) => K, init: K): K {
    return this.data.reduce(f, init);
  }

  get over() {
    if (this.data.length === 0) {
      return undefined;
    }

    return this.data.reduce((acc, value) => {
      return Object.entries(value).reduce((vacc, [vkey, vvalue]) => {
        return {
          ...vacc,
          [vkey]: [...(acc[vkey as keyof T] || []), vvalue]
        };
      }, {} as { [K in keyof T]: T[K][] })
    }, {} as { [K in keyof T]: T[K][] })
  }

  get includes() {
    if (!this.over) {
      return undefined;
    }

    return Object.entries(this.over).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: (v: (typeof value)[number]) => value.includes(v)
      }
    }, {} as { [K in keyof T]: (v: T[K]) => boolean })
  }

  get exclude() {
    if (!this.over) {
      return undefined;
    }

    return Object.entries(this.over).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: (...vs: (typeof value)) => this.filter((vv) => !vs.includes(vv[key as keyof T]))
      }
    }, {} as { [K in keyof T]: (...v: T[K][]) => Entity<T> })
  }

  get include() {
    if (!this.over) {
      return undefined;
    }

    return Object.entries(this.over).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: (...vs: (typeof value)) => this.filter((vv) => vs.includes(vv[key as keyof T]))
      }
    }, {} as { [K in keyof T]: (...v: T[K][]) => Entity<T> })
  }

  get findAll() {
    if (!this.over) {
      return undefined;
    }

    return Object.entries(this.over).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: (...vs: (typeof value)) => this.filter((vv) => vs.includes(vv[key as keyof T]))
      }
    }, {} as { [K in keyof T]: (...v: T[K][]) => Entity<T> })
  }

  get find() {
    if (!this.over) {
      return undefined;
    }

    return Object.entries(this.over).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: (v: (typeof value)[number]) => this.filter((vv) => vv[key as keyof T] === v)
      }
    }, {} as { [K in keyof T]: (v: T[K]) => Entity<T> })
  }

  get findOne() {
    if (!this.over) {
      return undefined;
    }

    return Object.entries(this.over).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: (v: (typeof value)[number] | null | undefined) => this.filter((vv) => vv[key as keyof T] === v).data.at(0)
      }
    }, {} as { [K in keyof T]: (v: T[K] | null | undefined) => T })
  }
}
