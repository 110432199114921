import { createSelector } from "@reduxjs/toolkit";
import { Entity } from "../../core/repository/repository";
import { Capability } from "../capabilities/selectors";
import { ResourceTimeframe } from "../resource_timeframe/selectors";
import { RootState, selectState } from "../store";
import { ResourceInterface } from "./interfaces";

export const selectResourceState = createSelector(
  [selectState],
  (state) => state.resources,
);

export const selectResourcesStatus = createSelector(
  [selectResourceState],
  (state) => state.status,
);

export const selectResourcesLoadingItem = createSelector(
  [selectResourceState],
  (state) => state.loadingItem,
);

export const selectResources = createSelector(
  [selectResourceState],
  (state) => state.value,
);

export const selectResourceRepository = createSelector(
  [selectResourceState],
  (state) => new Entity(state.value),
);

export const selectResourceEntity = createSelector(
  [selectState],
  (state) => new Resource(state),
);

export class Resource {
  private data: ResourceInterface[];

  constructor(private state: RootState) {
    this.data = state.resources.value;
  }

  fmap<OUT>(f: (v: ResourceInterface, i: number, list: ResourceInterface[]) => OUT): OUT[] {
    return this.data.map(f);
  }

  map<OUT>(f: (v: Resource, i: number, list: Resource) => OUT): OUT[] {
    return this.data.map((resource, i) => f(Resource.from([resource], this.state), i, Resource.from(this.data, this.state)));
  }

  filter(f: (resource: Resource, i: number, list: Resource) => boolean): Resource {
    const resources = this.data.filter((resource, i) => f(Resource.from([resource], this.state), i, Resource.from(this.data, this.state)));

    return Resource.from(resources, this.state);
  }

  static from(data: ResourceInterface[], state: RootState): Resource {
    const resource = new Resource(state);
    resource.data = data;

    return resource;
  }

  set(data: ResourceInterface[]) {
    const resource = new Resource(this.state);
    resource.data = data;

    return resource;
  }

  then(f: (v: ResourceInterface, i: number, list: ResourceInterface[]) => ResourceInterface): Resource {
    return this.set(this.fmap(f));
  }

  get length() {
    return this.data.length;
  }

  get capability() {
    const capabilities = this.state.capabilities.value;

    const resourceCapabilities = capabilities.filter((capability) => {
      return this.id.includes(capability.resource_id);
    });

    return Capability.from(resourceCapabilities, this.state);
  }

  get resourceTimeframe() {
    const timeframes = this.state.resourceTimeframes.value;

    const resourceTimeframes = timeframes.filter((timeframe) => {
      return this.id.includes(timeframe.resource_id);
    });

    return ResourceTimeframe.from(resourceTimeframes, this.state);
  }

  get name() {
    return this.data.map(({ name }) => name);
  }

  get description() {
    return this.data.map(({ description }) => description);
  }

  get id() {
    return this.data.map(({ id }) => id);
  }

  get find() {
    const resource: Resource = this;
    return {
      id(id: string) {
        return resource.filter((resource) => {
          return resource.id.includes(id);
        })
      }
    };
  }
}
