import { createSelector } from "@reduxjs/toolkit";
import { Entity } from "../../../core/repository/repository";
import { selectState } from "../../store";

export const selectIndustryTypesState = createSelector(
  [selectState],
  (state) => state.constants.industryTypes,
);

export const selectIndustryTypesStatus = createSelector(
  [selectIndustryTypesState],
  (state) => state.status,
);

export const selectIndustryTypes = createSelector(
  [selectIndustryTypesState],
  (state) => state.value,
);

export const selectIndustryTypesRepository = createSelector(
  [selectIndustryTypesState],
  (state) => new Entity(state.value),
);
