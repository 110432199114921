import { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { PageWrapper } from "../../components/page-wrapper/page-wrapper";
import { Ternary } from "../../components/utils/ternary";
import { Resource, selectResourceEntity } from "../../store/resource/selectors";
import { ResourceTimeframeInterface } from "../../store/resource_timeframe/interfaces";
import { selectResourceTimeframes } from "../../store/resource_timeframe/selectors";
import { EditResource } from "./edit-resource/edit-resource";
import { ResourcesList } from "./resources-list";

export const ResourceContext = createContext<{
  currentResource?: Resource,
  resources?: Resource,
  capabilities: string[],
  editTimeframesModal: boolean,
  resourceTimeframes: ResourceTimeframeInterface[],
  setEditTimeframesModal: (value: boolean) => void,
  setCapabilities: (capabilities: string[]) => void,
  setCurrentResource: (resource?: Resource) => void,
  setCreateResource: (value: boolean) => void,
}>({
  capabilities: [],
  editTimeframesModal: false,
  resources: undefined,
  resourceTimeframes: [],
  setEditTimeframesModal: () => {},
  setCapabilities: () => {},
  setCurrentResource: () => {},
  setCreateResource: () => {},
});

export const ResourcePage = () => {
  const resources = useSelector(selectResourceEntity);
  const resourceTimeframes = useSelector(selectResourceTimeframes);

  const [currentResource, setCurrentResource] = useState<Resource>();
  const [capabilities, setCapabilities] = useState<string[]>([]);
  const [createResource, setCreateResource] = useState(false);
  const [editTimeframesModal, setEditTimeframesModal] = useState(false);

  return (
    <PageWrapper page="resources information">
      <ResourceContext.Provider value={{
        currentResource,
        capabilities,
        editTimeframesModal,
        resources,
        resourceTimeframes,
        setEditTimeframesModal,
        setCapabilities,
        setCurrentResource,
        setCreateResource,
      }}>
        <Ternary condition={!!(currentResource || createResource)}>
          <EditResource />

          <ResourcesList />
        </Ternary>
      </ResourceContext.Provider>
    </PageWrapper>
  );
}

