import {
  AddOutlined,
  ArrowBack,
  ArrowForwardIosOutlined,
  CloseOutlined,
} from "@mui/icons-material";
import { Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrencySelector } from "../../../components/currency-selector/currency-selector";
import { countries } from "../../../core/constants/countries";
import { serviceColors } from "../../../core/constants/service-colors";
import { translations } from "../../../core/constants/translations";
import { useLangParam } from "../../../core/hooks";
import { selectCommerce, selectCommerceId } from "../../../store/commerce/selectors";
import { ServiceInterface } from "../../../store/service/interfaces";
import { selectServices } from "../../../store/service/selectors";
import {
  addServiceThunk,
  removeServiceThunk,
  updateServiceThunk,
} from "../../../store/service/thunks";
import { AppDispatch } from "../../../store/store";

export const CatalogStage = ({
  onContinue,
  onBack,
}: {
  onContinue: () => void;
  onBack: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Onboarding"]["Services"];

  const services = useSelector(selectServices);

  const [choosenService, setChoosenService] = useState<string>();
  const [createService, setCreateService] = useState(false);
  const service = services.find((s) => s.id === choosenService);

  return (
    <div className={`bg-white max-lg:w-80 lg:min-w-96 h-max rounded-lg flex flex-col items-center`}>
      <div className="border-b border-gray-300 w-full flex items-center justify-center py-6">
        <div className={`relative w-0 -left-10 lg:-left-64`}>
          <div
            className="lg:hidden cursor-pointer"
            onClick={() => {
              if (!!choosenService || createService) {
                setChoosenService(undefined);
                setCreateService(false);
              } else {
                onBack();
              }
            }}
          >
            <ArrowBack />
          </div>

          <div
            className="max-lg:hidden cursor-pointer"
            onClick={() => {
              onBack();
            }}
          >
            <ArrowBack />
          </div>
        </div>

        <div className="w-[200px] h-3 overflow-hidden rounded-full bg-gray-100">
          <div className="w-[120px] h-3 rounded-full bg-purple-300" />
        </div>
      </div>

      <p className={`text-xl px-8 lg:text-2xl mt-4 mb-4 font-bold`}>
        {translation["Start adding services"][lang]}
      </p>

      <p className={`max-lg:px-8 text-sm text-gray-500`}>
        {translation["Description"][lang]}
      </p>

      <div className={`flex max-lg:flex-col`}>
        <div className={`max-lg:hidden w-80 p-8 max-h-[480px] lg:w-96 lg:p-8 lg:border-r lg:border-r-gray-300 flex flex-col`}>
          <div className="flex flex-col overflow-y-auto">
            {services.map(({ name, id }, i, list) => {
              return (
                <ServiceItem
                  choosen={choosenService === id}
                  last={list.length - 1 === i}
                  name={name ?? `Service #${i + 1}`}
                  onOpen={() => {
                    setChoosenService(id)
                    setCreateService(false);
                  }}
                  onDelete={() => {
                    dispatch(removeServiceThunk(id));
                  }}
                />
              );
            })}
          </div>

          <div
            className={`flex gap-4 w-full cursor-pointer py-2 px-2 mt-2 mb-4 hover:bg-gray-50 ${createService ? "bg-gray-100" : ""} rounded-xl`}
            onClick={() => {
              setCreateService(true);
              setChoosenService(undefined);
            }}
          >
            <AddOutlined />

            <p className="">
              {translation["Add new Service"][lang]}
            </p>
          </div>

          <Button
            variant="contained"
            sx={{
              width: "100%",
              background: "rgb(192 132 252)",
              "&:hover": {
                background: "rgb(216 180 254)",
              },
            }}
            onClick={() => {
              onContinue();
            }}
            disabled={services.length === 0}
          >
            {translation["Continue"][lang]}
          </Button>
        </div>

        {!!choosenService || createService ||
          <div className={`lg:hidden w-80 p-8 max-h-[480px] lg:w-96 lg:p-8 lg:border-r lg:border-r-gray-300 flex flex-col`}>
            <div className="flex flex-col overflow-y-scroll">
              {services.map(({ name, id }, i, list) => {
                return (
                  <ServiceItem
                    choosen={choosenService === id}
                    last={list.length - 1 === i}
                    name={name ?? `Service #${i + 1}`}
                    onOpen={() => {
                      setChoosenService(id)
                      setCreateService(false);
                    }}
                    onDelete={() => {
                      dispatch(removeServiceThunk(id));
                    }}
                  />
                );
              })}
            </div>

            <div
              className={`flex gap-4 w-full cursor-pointer py-2 px-2 mt-2 mb-4 hover:bg-gray-50 ${createService ? "bg-gray-100" : ""} rounded-xl`}
              onClick={() => {
                setCreateService(true);
                setChoosenService(undefined);
              }}
            >
              <AddOutlined />

              <p className="">
                {translation["Add new Service"][lang]}
              </p>
            </div>

            <Button
              variant="contained"
              sx={{
                width: "100%",
                background: "rgb(192 132 252)",
                "&:hover": {
                  background: "rgb(216 180 254)",
                },
              }}
              onClick={() => {
                onContinue();
              }}
              disabled={services.length === 0}
            >
              {translation["Continue"][lang]}
            </Button>
          </div>
        }

        <div className="max-lg:hidden">
          <ServiceModifyStage
            key={service?.id}
            service={service}
            onBack={(serviceId) => {
              setChoosenService(serviceId);
              setCreateService(false);
            }}
          />
        </div>

        {(!!choosenService || createService) &&
          <div className="lg:hidden">
            <ServiceModifyStage
              key={service?.id}
              service={service}
              onBack={() => {
                setChoosenService(undefined);
                setCreateService(false);
              }}
            />
          </div>
        }
      </div>
    </div>
  );
};

const ServiceItem = ({
  name,
  last,
  onOpen,
  onDelete,
  choosen,
}: {
  name: string;
  last: boolean;
  onOpen: () => void;
  onDelete: () => void;
  choosen: boolean,
}) => {
  const border = "border-b border-gray-300";

  return (
    <div
      className={`${!last ? border : undefined}`}
    >
      <div
        className={`flex items-center gap-4 py-3 my-1 hover:bg-gray-50 cursor-pointer ${choosen ? "bg-gray-100" : ""} rounded-xl px-2`}
        onClick={() => onOpen()}
      >
        <CloseOutlined
          sx={{
            height: "22px",
            width: "22px",
            ml: "2px",
            cursor: "pointer",
            color: "grey",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        />

        <p className="">{name}</p>

        <ArrowForwardIosOutlined
          sx={{
            ml: "auto",
            height: "22px",
            width: "22px",
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};

const ServiceModifyStage = ({
  service,
  onBack,
}: {
  service?: ServiceInterface;
  onBack: (service: string) => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const lang = useLangParam();

  const translation = translations["Onboarding"]["New service"];

  const [loading, setLoading] = useState(false);

  const commerceId = useSelector(selectCommerceId);

  const commerce = useSelector(selectCommerce);

  const [name, setName] = useState(service?.name);
  const [description, setDescription] = useState(service?.description);
  const [hour, setHour] = useState(
    Math.floor((service?.duration ?? 0) / 60) || undefined,
  );
  const [minute, setMinute] = useState(
    (service?.duration ?? 0) % 60 || undefined,
  );
  const [price, setPrice] = useState(service?.price);

  const countryCurrency = countries.find(({ name }) => {
    return name === commerce?.country;
  })?.currencyCode;

  const [currency, setCurrency] = useState(service?.currency ?? countryCurrency);

  return (
    <div className={`bg-white w-80 lg:w-96 h-max rounded-lg flex flex-col items-center`}>
      <div className="w-full p-8 flex flex-col gap-2">
        <TextField
          label={translation["Name"][lang]}
          size="small"
          sx={{
            width: "100%",
          }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <TextField
          label={translation["Description Title"][lang]}
          multiline
          size="small"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />

        <div>
          <p className="text-xs text-gray-600 mb-2">
            {translation["Service duration"][lang]}
          </p>

          <div className="flex gap-2">
            <TextField
              label={translation["Hours"][lang]}
              type="number"
              size="small"
              value={hour}
              InputProps={{
                inputProps: {
                  min: 0,
                }
              }}
              onKeyPress={(event) => {
                if (event.code === "Minus")
                  event.preventDefault();
              }}
              onChange={(e) => {
                setHour(+e.target.value);
              }}
            />

            <TextField
              label={translation["Minutes"][lang]}
              type="number"
              size="small"
              value={minute}
              InputProps={{
                inputProps: {
                  min: 0,
                }
              }}
              onKeyPress={(event) => {
                if (event.code === "Minus")
                  event.preventDefault();
              }}
              onChange={(e) => {
                setMinute(+e.target.value);
              }}
            />
          </div>
        </div>

        <TextField
          label={translation["Price"][lang]}
          type="number"
          size="small"
          value={price}
          onChange={(e) => {
            setPrice(+e.target.value);
          }}
        />

        <CurrencySelector
          placeholder={translation["Currency"][lang]}
          currency={currency}
          setCurrency={(value) => {
            setCurrency(value);
          }}
        />

        <p className="text-xs text-gray-600 py-1">
          * These fields are mandatory
        </p>

        <Button
          variant="contained"
          sx={{
            width: "100%",
            background: "rgb(192 132 252)",
            "&:hover": {
              background: "rgb(216 180 254)",
            },
          }}
          disabled={
            !name || !description || (!hour && !minute) || !price || !currency || loading || (price || 0) < 0 || (hour || 0) < 0 || (minute || 0) < 0 
          }
          onClick={() => {
            setLoading(true);
            if (service?.id)
              dispatch(
                updateServiceThunk({
                  id: service?.id,
                  name,
                  description,
                  duration: (hour ?? 0) * 60 + (minute ?? 0) || undefined,
                  price,
                  currency,
                }),
              )
                .unwrap()
                .then(() => {
                  setLoading(false);
                  onBack(service.id);
                });
            else {
              console.log(commerceId);
              commerceId &&
                dispatch(
                  addServiceThunk({
                    commerce_id: commerceId,
                    name,
                    description,
                    duration: (hour ?? 0) * 60 + (minute ?? 0) || undefined,
                    price,
                    currency,
                    color:
                      serviceColors[
                        Math.floor(Math.random() * serviceColors.length)
                      ],
                  }),
                )
                  .unwrap()
                  .then(({ id }) => {
                    setLoading(false);
                    onBack(id);
                  });
            }
          }}
        >
          {loading ?
            <CircularProgress
              size={25}
              sx={{
                color: "rgb(192 132 252)",
              }}
            />
            : translation["Save"][lang]}
        </Button>
      </div>
    </div>
  );
};
