import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PlatformButton } from "../../components/buttons/button";
import { Resource, selectResourceEntity } from "../../store/resource/selectors";
import { Service } from "../../store/service/selectors";
import { selectState } from "../../store/store";
import { ResourceContext } from "./resource-page";

export const ResourcesList = () => {
  const resources = useSelector(selectResourceEntity);
  const rootState = useSelector(selectState);
  const ctx = useContext(ResourceContext);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const resourceId = searchParams.get("id") || undefined;
    const resource = !!resourceId ? resources.find.id(resourceId) : undefined;

    ctx.setCurrentResource(resource);
    ctx.setCapabilities(resource?.capability.service.id || []);
  }, [ctx, searchParams]);

  return (
    <div className={`gap-5 pt-5 lg:gap-10 lg:pt-10 flex flex-col h-[calc(100%-60px)]`}>
      <div className={`flex items-center h-16 w-full bg-white/50 rounded-2xl p-3 lg:p-5`}>
        <p className={`text-slate-700 text-xl lg:text-2xl font-medium`}>
          Resources
        </p>

        <PlatformButton
          title="Add Resource"
          className="w-30 h-10 bg-indigo-500 hover:bg-indigo-400"
          onClick={async () => {
            rootState.commerce.value?.id && ctx.setCurrentResource(Resource.from([{
              id: "",
              name: "",
              commerce_id: rootState.commerce.value.id,
              description: "",
            }], rootState));
            ctx.setCreateResource(true);
          }}
        />
      </div>

      <div className={`
        flex flex-col justify-center w-full bg-white/50 rounded-2xl pb-5 pt-2
        h-auto max-h-full
      `}>
        <div className={`grid grid-cols-3 lg:grid-cols-3 px-5 py-3`}>
          <p>Name</p>

          <p>Description</p>

          <p className="ml-auto">Capability</p>
        </div>

        {resources.map(ResourceItem(navigate))}
      </div>
    </div>
  );
}

const ResourceItem = (navigate: (url: string) => void) => (resource: Resource, i: number, list: Resource) => {
  return (
    <div
      key={i}
      className={`
        grid grid-cols-3 lg:grid-cols-3 px-5 py-3 hover:bg-gray-200/50
        cursor-pointer border-t border-t-1 items-center
        ${i === list.length - 1 ? "border-b border-b-1" : ""}
      `}
      onClick={() => {
        navigate(`/resource/?id=${resource.id.at(0)}`);
      }}
    >
      <p>
        {resource.name.at(0) || `Resource #${i + 1}`}
      </p>

      <p>
        {resource.description.at(0) || `-`}
      </p>

      <div className="flex ml-auto gap-2">
        {resource.capability.service.map(ServiceItem)}
      </div>
    </div>
  );
}

const ServiceItem = (service: Service) => {
  return (
    <div className={`h-9 w-9 rounded-full flex items-center justify-center ${service.color.at(0)}`}>
      <p className="font-medium text-sm">
        {service.name.at(0)?.slice(0, 2).toUpperCase()}
      </p>
    </div>
  )
}
