import { useContext } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "../../../components/checkbox/checkbox";
import { selectServiceEntity, Service } from "../../../store/service/selectors";
import { ResourceContext } from "../resource-page";


export const ResourceCapabilities = () => {
  const allServices = useSelector(selectServiceEntity);
  const ctx = useContext(ResourceContext);

  return (
    <div className={`flex flex-col w-full lg:w-[500px] h-max bg-white/50 rounded-2xl`}>
      <div className="border-b border-b-1 p-5">
        <p className="text-slate-700 text-xl font-medium">
          Capability of your resources
        </p>

        <p className="text-slate-500 text-sm mt-2">
          Add the services that this resource provides
        </p>
      </div>
      
      <div className="flex flex-col gap-5 p-5">
        {allServices.map(CapabilityItem(ctx.capabilities, ctx.setCapabilities))}
      </div>
    </div>
  );
}

const CapabilityItem = (
  capabilities: string[],
  setCapabilities: (capabilities: string[]) => void,
) => (service: Service, i: number) => {
  const value = capabilities.some((service_id) => service.id.includes(service_id));

  return (
    <div
      key={i}
      className="flex items-center gap-3"
    >
      <Checkbox
        value={value}
        onClick={() => {
          if (value) {
            setCapabilities(capabilities.filter((capability) => {
              return !service.id.includes(capability);
            }));
          } else {
            setCapabilities([...capabilities, ...service.id]);
          }
        }}
      />

      <div className={`h-9 w-9 rounded-full flex items-center justify-center ${service.color}`}>
        <p className="font-medium text-sm">
          {service.name?.at(0)?.slice(0, 2).toUpperCase()}
        </p>
      </div>

      <p className="text-slate-700">
        {service.name}
      </p>
    </div>
  ); 
}

