import { PageWrapper } from "../../components/page-wrapper/page-wrapper";
import { useLoader } from "../../core/loader/loader";
import { ResourceSchedulePageMobile } from "./resource-schedule-page-mobile";
import { ResourceTimeframesList } from "./resource-timeframes-list/resource-timeframes-list";

export const ResourceSchedulePage = () => {
  useLoader();

  return (
    <PageWrapper page="resources-schedule">
      <div className="lg:hidden">
        <ResourceSchedulePageMobile />
      </div>

      <div className="max-lg:hidden">
        <ResourceTimeframesList />
      </div>
    </PageWrapper>
  );
}
