import { AppsOutlined, AutoStoriesOutlined, CalendarMonthOutlined, ChatOutlined, NaturePeopleOutlined, SettingsOutlined } from "@mui/icons-material";
import { PropsWithChildren, ReactNode } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const PageMenuContents = ({
  page,
  collapse = false,
}: {
  page: string,
  collapse?: boolean,
}) => {
  return (
    <PageMenuWrapper collapse={collapse} currentPage={page}>
      <Catalog />
      <Resources childPages={["resources information", "resources-schedule"]}>
        <ResourcesInformation />
        <ResourcesSchedule />
      </Resources>
      <Chat />
      <Calendar />
      <Integrations />
      <Configuration />
    </PageMenuWrapper>
  );
}

const PageMenuWrapper = ({ children, collapse, currentPage }: PropsWithChildren<{ collapse: boolean, currentPage: string }>) => {
  return (
    <div className="flex flex-col">
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as JSX.Element, { collapse, currentPage });
      })}
    </div>
  );
}

const Catalog = pageItem("Catalog", "catalog", "/catalog", AutoStoriesOutlined);

const Resources = pageItem("Resources", "resources", "/resource", NaturePeopleOutlined);
const ResourcesInformation = pageSubItem("Resources Information", "resources information", "/resource");
const ResourcesSchedule = pageSubItem("Resources Schedule", "resources-schedule", "/resource/schedule");

const Chat = pageItem("Chat", "chat", "/chat", ChatOutlined);
const Calendar = pageItem("Calendar", "calendar", "/calendar", CalendarMonthOutlined);
const Integrations = pageItem("Integrations", "integrations", "/integrations", AppsOutlined);
const Configuration = pageItem("Configuration", "configuration", "/configuration", SettingsOutlined);

function pageItem(name: string, page: string, url: string, Icon: typeof AutoStoriesOutlined) {
  return ({ collapse, currentPage, children, childPages }: { collapse?: boolean, currentPage?: string, childPages?: string[], children?: ReactNode }) => {
    const navigate = useNavigate();
    const focus = page === currentPage;
    const childFocus = childPages?.includes(currentPage || "");

    return (
      <div>
        <div
          className={`${focus ? "bg-indigo-200/50 rounded-md" : ""} hover:bg-indigo-100/50 select-none cursor-pointer
            mb-2 px-3 h-14 flex items-center ${collapse ? "" : "gap-2"}`}
          onClick={() => {
            navigate(url);
          }}
        >
          <Icon style={{
            color: "rgb(51 65 85)",
            width: collapse ? "30px" :"25px",
            height: collapse ? "30px" :"25px",
            transition: "width .15s linear, height .15s linear",
          }} />

          <p className={`
            text-slate-700 ${focus ? "font-semibold" : ""} text-lg
            ${collapse ? "w-0 opacity-0" : "w-max opacity-100"} transition-all
          `}>
            {name}
          </p>
        </div>

        {!collapse && childFocus &&
          <div className="flex flex-col pl-5 gap-2">
            {React.Children.map(children, (child) => {
              return React.cloneElement(child as JSX.Element, { collapse, currentPage });
            })}
          </div>
        }
      </div>
    );
  };
}

function pageSubItem(name: string, subpage: string, url: string) {
  return ({ currentPage, children }: { collapse?: boolean, currentPage?: string, children?: ReactNode }) => {
    const navigate = useNavigate();

    return (
      <div
        className="cursor-pointer select-none"
      >
        <div
          className={`${subpage === currentPage ? "bg-indigo-200/50 rounded-md" : ""} hover:bg-indigo-100/50 px-3 h-10 flex items-center gap-2}`}
          onClick={() => {
            navigate(url);
          }}
        >
          <p className="pr-3">-</p>

          <p className={`
            text-slate-700 ${subpage === currentPage ? "font-semibold" : ""} text-md
            "w-max opacity-100 transition-all
          `}>
            {name}
          </p>
        </div>

        <div className="flex-column pl-5">
          {children}
        </div>
      </div>
    );
  };
}
