import { createSelector } from "@reduxjs/toolkit";
import { RootState, selectState } from "../store";
import { ResourceTimeframeInterface } from "./interfaces";

export const selectResourceTimeframeState = createSelector(
  [selectState],
  (state) => state.resourceTimeframes,
);

export const selectResourceTimeframeStatus = createSelector(
  [selectResourceTimeframeState],
  (state) => state.status,
);

export const selectResourceTimeframes = createSelector(
  [selectResourceTimeframeState],
  (state) => state.value,
);

export class ResourceTimeframe {
  private data: ResourceTimeframeInterface[];

  constructor(private state: RootState) {
    this.data = state.resourceTimeframes.value;
  }

  static from(data: ResourceTimeframeInterface[], state: RootState): ResourceTimeframe {
    const resource = new ResourceTimeframe(state);
    resource.data = data;

    return resource;
  }

  filter(f: (timeframe: ResourceTimeframe, i: number, list: ResourceTimeframe) => boolean): ResourceTimeframe {
    const timeframes = this.data.filter((resource, i) => f(ResourceTimeframe.from([resource], this.state), i, ResourceTimeframe.from(this.data, this.state)));

    return ResourceTimeframe.from(timeframes, this.state);
  }

  map<OUT>(f: (v: ResourceTimeframe, i: number, list: ResourceTimeframe) => OUT): OUT[] {
    return this.data.map((resource, i) => f(ResourceTimeframe.from([resource], this.state), i, ResourceTimeframe.from(this.data, this.state)));
  }

  get length() {
    return this.data.length;
  }

  get day() {
    return this.data.map(({ day }) => day);
  }

  get start() {
    return this.data.map(({ start }) => start);
  }

  get duration() {
    return this.data.map(({ duration }) => duration);
  }

  get resource_id() {
    return this.data.map(({ resource_id }) => resource_id);
  }

  get id() {
    return this.data.map(({ id }) => id);
  }
}
