import { useState } from "react";
import { PageLogo } from "../page-logo";
import { PageMenuContents } from "./menu";

export const PageMenu = ({
  page,
}: {
  page: string,
}) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div className="h-full">
      <div className={`
        flex flex-col gap-10 h-full bg-white/50 p-2 transition-all
        ${collapse ? "w-[90px] items-center" : "w-72"}
      `}>
        <PageLogo
          collapse={collapse}
          onCollapse={() => {
            setCollapse(!collapse)
          }}
        />

        <PageMenuContents
          collapse={collapse}
          page={page}
        />
      </div>
    </div>
  );
}
