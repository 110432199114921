import { Box, Button, TextField } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { selectUserErrors } from "../../../store/user/selectors";
import { verifyOtpThunk } from "../../../store/user/thunks";

export const VerifyOtpStage = ({
  email,
  onSuccess,
}: {
  email: string;
  onSuccess: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const error = useSelector(selectUserErrors);

  const [code, setCode] = useState(new Array(6).fill(""));

  const refs = new Array(6).fill(0).map(() => createRef<HTMLDivElement>());

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      let data = event.clipboardData?.getData("Text") || "";
      setCode(data.slice(0, 6).padEnd(6, "0").split(""));
    };

    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  return (
    <div className={`bg-white w-80 h-96 p-8 lg:w-96 lg:h-96 lg:p-16 rounded-lg flex flex-col items-center gap-3 justify-center`}>
      <p className="text-2xl font-bold">Please check your email</p>

      <p className="text-xs mb-6 text-center text-gray-500">
        We have emailed a 6-digit confirmation code to {email}, please enter the
        code in below box to verify your email
      </p>

      <div className="flex gap-1">
        {new Array(6).fill(0).map((_, i) => {
          return (
            <Box key={i}>
              <style>
                {`  
                  input[type="number"] {
                    -moz-appearance: textfield;
                  }

                  /* For Other Browsers */
                  input::-webkit-outer-spin-button,
                  input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                `}
              </style>
              <TextField
                value={code[i]}
                inputRef={refs[i]}
                onFocus={(event) => {
                  event.target.select();
                }}
                onKeyUp={(event) => {
                  if (event.key === "Backspace") {
                    if (i >= 1) {
                      refs[i - 1].current?.focus();
                    }
                  }
                }}
                onChange={(e) => {
                  const c = e.target.value;

                  const str = [...code];
                  str[i] = c;
                  setCode(str);

                  if (i < 5 && c) {
                    refs[i + 1].current?.focus();
                  }
                }}
                placeholder="-"
                inputProps={{
                  maxLength: 1,
                  sx: {
                    textAlign: "center",
                    "-webkit-appearance": "none",
                  },
                }}
              />
            </Box>
          );
        })}
      </div>

      <Button
        variant="contained"
        size="large"
        sx={{
          width: "100%",
          background: "oklch(30% 0 0)",
          "&:hover": {
            background: "oklch(40% 0 0)",
          },
        }}
        onClick={() => {
          dispatch(
            verifyOtpThunk({
              type: "recovery",
              email,
              token: code.join(""),
            }),
          )
            .unwrap()
            .then(() => {
              onSuccess();
            })
            .catch(() => {});
        }}
      >
        VERIFY EMAIL
      </Button>

      {error && <p className="text-red-600">{error}</p>}
    </div>
  );
};
