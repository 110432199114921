import { AddOutlined } from "@mui/icons-material";
import { createContext, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WeekScheduleModal } from "../../../components/week-schedule/week-schedule-modal";
import { Day } from "../../../core/interfaces";
import { capitalize } from "../../../core/utils/string-utils";
import { Resource, selectResourceEntity } from "../../../store/resource/selectors";
import { selectResourceTimeframes } from "../../../store/resource_timeframe/selectors";
import { addResourceTimeframesThunk, removeResourceTimeframesThunk, updateResourceTimeframesThunk } from "../../../store/resource_timeframe/thunks";
import { AppDispatch } from "../../../store/store";

const ResourceTimeframeListContext = createContext<{
  setEditTimeframesModal: (value: boolean) => void,
  setCurrentResource: (resource?: Resource) => void,
}>({
  setEditTimeframesModal: () => {},
  setCurrentResource: () => {},
});

export const ResourceTimeframesList = () => {
  const dispatch = useDispatch<AppDispatch>();

  const resources = useSelector(selectResourceEntity);
  const navigate = useNavigate();

  const [editTimeframesModal, setEditTimeframesModal] = useState(false);
  const [currentResource, setCurrentResource] = useState<Resource>();

  const resourceTimeframes = useSelector(selectResourceTimeframes);
  const timeframes = resourceTimeframes.filter(({ resource_id }) => {
    return currentResource?.id.includes(resource_id);
  });

  return (
    <ResourceTimeframeListContext.Provider value={{
      setEditTimeframesModal,
      setCurrentResource,
    }}>
    <div className={`gap-5 pt-5 lg:gap-10 lg:pt-10 flex flex-col h-[calc(100%-60px)]`}>
      <div className={`flex items-center h-16 w-full bg-white/50 rounded-2xl p-3 lg:p-5`}>
        <p className={`text-slate-700 text-xl lg:text-2xl font-medium`}>
          Resources Schedule
        </p>
      </div>

      {editTimeframesModal &&
        <WeekScheduleModal
          onClose={() => {
            setEditTimeframesModal(false);
            setCurrentResource(undefined);
          }}
          timeframes={timeframes}
          onAddTimeframe={(t) => {
            currentResource?.fmap((resource) => {
              dispatch(
                addResourceTimeframesThunk({
                  ...t,
                  resource_id: resource.id,
                }),
              );
            });
          }}
          onRemoveTimeframes={(ids) => {
            dispatch(removeResourceTimeframesThunk(ids));
          }}
          onUpdateTimeframes={(t) => {
            dispatch(updateResourceTimeframesThunk(t));
          }}
        />
      }

      <div className={`
        flex flex-col justify-center w-full bg-white/50 rounded-2xl pb-5 pt-2
        h-auto max-h-full
      `}>
        <div className={`grid grid-cols-8 px-5 py-3`}>
          <p className="text-slate-700">Name</p>

          {Object.values(Day).map((name) => {
            return (
              <p className="text-slate-700 text-center">
                {capitalize(name)}
              </p>
            );
          })}
        </div>

        {resources.map(ResourceScheduleItem(navigate))}
      </div>
    </div>
    </ResourceTimeframeListContext.Provider>
  );
}

const ResourceScheduleItem = (navigate: (url: string) => void) => (resource: Resource, i: number, list: Resource) => {
  return (
    <div
      key={i}
      className={`
        grid grid-cols-8 cursor-pointer border-t border-t-1 justify-center
        ${i === list.length - 1 ? "border-b border-b-1" : ""}
      `}
    >
      <p
        className="text-slate-700 border-r border-r-1 px-5 pt-6 hover:bg-gray-200/50"
        onClick={() => {
          navigate(`/resource/?id=${resource.id.at(0)}`);
        }}
      >
        {resource.name.at(0) || `Resource #${i + 1}`}
      </p>

      {Object.values(Day).map((day) => {
        return <TimeframeContainer
          resource={resource}
          day={day}
        />
      })}
    </div>
  );
}

const TimeframeContainer = (props: {resource: Resource, day: Day}) => {
  const timeframes = props.resource.resourceTimeframe.filter((timeframe) => {
    return timeframe.day.includes(props.day);
  });
  const ctx = useContext(ResourceTimeframeListContext);

  const [hover, setHover] = useState(false);

  return (
    <div 
      className="flex flex-col gap-2 items-center border-r border-r-1 px-5 py-3 hover:bg-gray-200/50"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        ctx.setEditTimeframesModal(true);
        ctx.setCurrentResource(props.resource);
      }}
    >
      {timeframes.length === 0 && hover &&
        <AddOutlined sx={{
          marginTop: "11px",
          color: "rgb(51 65 85)",
        }} />
      }

      {timeframes.map((timeframe) => {
        const start = timeframe.start.at(0)?.slice(0, 5);
        const [hour, minute] = start?.split(":")!;
        const mend = parseInt(hour) * 60 + parseInt(minute) + timeframe.duration.at(0)!;
        const end = `${Math.floor(mend / 60).toString().padStart(2, "0")}:${(mend % 60).toString().padStart(2, "0")}`;

        return (
          <div className="flex items-center justify-center bg-indigo-200 hover:bg-indigo-300 rounded-lg h-12 w-28 cursor-pointer">
            <p className="text-slate-700">
              {start} - {end}
            </p>
          </div>
        );
      })}
    </div>
  );
}
