import { useContext } from "react";
import { Input } from "../../../components/inputs/input";
import { ResourceContext } from "../resource-page";


export const ResourceInformation = () => {
  const ctx = useContext(ResourceContext);

  return (
    <div className={`flex flex-col bg-white/50 rounded-2xl`}>
      <div className="border-b border-b-1 p-5">
        <p className="text-slate-700 text-xl font-medium">
          Resource Information
        </p>

        <p className="text-slate-500 text-sm mt-2">
          Add the name of the service and give a brief description of what it is
        </p>
      </div>

      <div className="flex flex-col gap-5 w-full p-5">
        <Input
          label="Name of the resource"
          placeholder="Add the name of your resource here"
          value={ctx.currentResource?.name.at(0) || ""}
          onChange={(value) => {
            if (!ctx.currentResource) return;

            ctx.setCurrentResource(
              ctx.currentResource.then((resource) => {
                return {
                  ...resource,
                  name: value,
                }
              })
            )
          }}
        />

        <Input
          textarea
          label="Description of your resource"
          placeholder="Add the description of your resource here"
          value={ctx.currentResource?.description.at(0) || ""}
          onChange={(value) => {
            if (!ctx.currentResource) return;

            ctx.setCurrentResource(
              ctx.currentResource.then((resource) => {
                return {
                  ...resource,
                  description: value,
                }
              })
            )
          }}
        />
      </div>
    </div>
  );
}
