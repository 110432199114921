import { createSelector } from "@reduxjs/toolkit";
import { RootState, selectState } from "../store";
import { ServiceInterface } from "./interfaces";

export const selectServiceState = createSelector(
  [selectState],
  (state) => state.services,
);

export const selectServiceStatus = createSelector(
  [selectServiceState],
  (state) => state.status,
);

export const selectServices = createSelector(
  [selectServiceState],
  (state) => state.value,
);

export const selectServiceEntity = createSelector(
  [selectState],
  (state) => new Service(state),
);

export class Service {
  private data: ServiceInterface[];

  constructor(private state: RootState) {
    this.data = state.services.value;
  }

  static from(data: ServiceInterface[], state: RootState): Service {
    const service = new Service(state);
    service.data = data;

    return service;
  }

  get capability() {
    const capabilities = this.state.capabilities.value;

    const serviceCapabilities = capabilities.filter((capability) => {
      return this.id.includes(capability.service_id);
    });

    return serviceCapabilities;
  }

  fmap<OUT>(f: (v: ServiceInterface, i: number, list: ServiceInterface[]) => OUT): OUT[] {
    return this.data.map(f);
  }

  map<OUT>(f: (v: Service, i: number, list: Service) => OUT): OUT[] {
    return this.data.map((service, i) => f(Service.from([service], this.state), i, Service.from(this.data, this.state)));
  }

  includes(service: Service) {
    return this.id.some((id) => service.id.includes(id));
  }

  get name() {
    return this.data.map(({ name }) => name);
  }

  get color() {
    return this.data.map(({ color }) => color);
  }

  get id() {
    return this.data.map(({ id }) => id);
  }
}
