export const translations = {
  "Get Started": {
    "Register": {
      "en": "Register",
      "es": "Registro",
      "pt": "Cadastre-se",
      "First Name": {
        "en": "First Name",
        "es": "Primer Nombre",
        "pt": "Primeiro nome"
      },
      "Last Name": {
        "en": "Last Name",
        "es": "Apellido",
        "pt": "Sobrenome"
      },
      "Email": {
        "en": "Email",
        "es": "Email",
        "pt": "Email"
      },
      "Code": {
        "en": "Code",
        "es": "Código",
        "pt": "Código"
      },
      "Phone": {
        "en": "Phone",
        "es": "Teléfono",
        "pt": "Telefone"
      },
      "Password": {
        "en": "Password",
        "es": "Constraseña",
        "pt": "Senha"
      },
      "Acceptance": {
        "en": "By registering, I agree to Magnetic Bunny [Terms of Service] and [Privacy Policy].",
        "es": "Al registrarme, acepto los [Términos de Servicio] y la [Política de Privacidad] de Magnetic Bunny.",
        "pt": "Ao me registrar, concordo com os [Termos de Serviço] e a [Política de Privacidade] do Magnetic Bunny."
      },
      "Button": {
        "en": "Register",
        "es": "Registro",
        "pt": "Cadastre-se"
      },
      "Singin": {
        "en": "Already have an account? Sign in here",
        "es": "¿Ya tienes una cuenta? Inicia sesión aquí",
        "pt": "Já tem uma conta? Entre aqui"
      }
    },
    "Login": {
      "en": "Login",
      "es": "Acceso",
      "pt": "Conecte-se",
      "Email": {
        "en": "Email",
        "es": "Email",
        "pt": "Email"
      },
      "Password": {
        "en": "Password",
        "es": "Constraseña",
        "pt": "Senha"
      },
      "Forgot password?": {
        "en": "Forgot password?",
        "es": "¿Olvidaste tu contraseña?",
        "pt": "Esqueceu sua senha?"
      },
      "Button": {
        "en": "Login",
        "es": "Acceder",
        "pt": "Conecte-se"
      },
      "Register": {
        "en": "Register",
        "es": "Registro",
        "pt": "Cadastre-se"
      }
    },
    "Password recovery": {
      "en": "Password recovery",
      "es": "Recuperación de contraseña",
      "pt": "Recuperação de senha",
      "Message": {
        "en": "Please enter the email address associated with your account and we will email you a link to reset your password.",
        "es": "Por favor ingresa la dirección de correo asociada a tu cuenta y te enviaremos un enlace para restablecer tu contraseña.",
        "pt": "Por favor, insira o endereço de e-mail associado à sua conta e enviaremos um link para redefinir sua senha."
      },
      "Email": {
        "en": "Email",
        "es": "Correo electrónico",
        "pt": "E-mail"
      },
      "Send Email": {
        "en": "Send Email",
        "es": "Enviar correo",
        "pt": "Enviar e-mail"
      },
      "Back": {
        "en": "Back",
        "es": "Volver",
        "pt": "Voltar"
      }
    }
  },
  "Onboarding": {
    "About your business": {
      "en": "About your business",
      "es": "Sobre tu negocio",
      "pt": "Sobre o seu negócio",
      "Company name": {
        "en": "Company name",
        "es": "Nombre de la empresa",
        "pt": "Nome da empresa"
      },
      "Code": {
        "en": "Code",
        "es": "Código",
        "pt": "Código"
      },
      "Phone": {
        "en": "Phone",
        "es": "Teléfono",
        "pt": "Telefone"
      },
      "Country": {
        "en": "Country",
        "es": "País",
        "pt": "País"
      },
      "Industry": {
        "en": "Industry",
        "es": "Industria",
        "pt": "Indústria"
      },
      "Business": {
        "en": "Business",
        "es": "Negocio",
        "pt": "Negócio"
      },
      "Continue": {
        "en": "Continue",
        "es": "Continuar",
        "pt": "Continuar"
      }
    },
    "Services": {
      "Start adding services": {
        "en": "Start adding services",
        "es": "Comienza a añadir servicios",
        "pt": "Comece a adicionar serviços"
      },
      "Description": {
        "en": "Services are the offerings you provide to your clients that require prior booking.",
        "es": "Los servicios son la prestación que ofreces a tus clientes que requiere reserva previa.",
        "pt": "Os serviços são as ofertas que você fornece aos seus clientes que exigem reserva prévia."
      },
      "Add new Service": {
        "en": "Add new Service",
        "es": "Añadir nuevo servicio",
        "pt": "Adicionar novo serviço"
      },
      "Continue": {
        "en": "Continue",
        "es": "Continuar",
        "pt": "Continuar"
      },
      "Validation": {
        "en": "To continue, you must add at least one service.",
        "es": "Para continuar debes añadir al menos un servicio.",
        "pt": "Para continuar, você deve adicionar pelo menos um serviço."
      }
    },
    "New service": {
      "Add service": {
        "en": "Add service",
        "es": "Añadir servicio",
        "pt": "Adicionar serviço"
      },
      "Description": {
        "en": "Add the basic information of your service. You will be able to modify this information later.",
        "es": "Añade la información básica de tu servicio, esta información la podrás modificar más adelante.",
        "pt": "Adicione as informações básicas do seu serviço. Você poderá modificar essas informações mais tarde."
      },
      "Name": {
        "en": "Name",
        "es": "Nombre",
        "pt": "Nome"
      },
      "Description Title": {
        "en": "Description",
        "es": "Descripción",
        "pt": "Descrição"
      },
      "Service duration": {
        "en": "Service duration",
        "es": "Duración del servicio",
        "pt": "Duração do serviço"
      },
      "Hours": {
        "en": "Hours",
        "es": "Horas",
        "pt": "Horas"
      },
      "Minutes": {
        "en": "Minutes",
        "es": "Minutos",
        "pt": "Minutos"
      },
      "Price": {
        "en": "Price",
        "es": "Precio",
        "pt": "Preço"
      },
      "Currency": {
        "en": "Currency",
        "es": "Moneda",
        "pt": "Moeda"
      },
      "Save": {
        "en": "Save",
        "es": "Guardar",
        "pt": "Salvar"
      },
      "Delete": {
        "en": "Delete",
        "es": "Borrar",
        "pt": "excluir"
      },
      "fields": {
        "en": "These fields are mandatory",
        "es": "Estos campos son obligatorios",
        "pt": "Esses campos são obrigatórios"
      }
    },
    "Resources": {
      "Start adding resources": {
        "en": "Start adding resources",
        "es": "Comienza a añadir recursos",
        "pt": "Comece a adicionar recursos"
      },
      "Add services": {
        "en": "Add services",
        "es": "Añade servicios",
        "pt": "Adicionar serviços"
      },
      "Name": {
        "en": "Name",
        "es": "Nombre",
        "pt": "Nome"
      },
      "Description Title": {
        "en": "Description",
        "es": "Descripción",
        "pt": "Descrição"
      },
      "Description": {
        "en": "Resources are those who carry out your service (your collaborators), or alternatively, the place your clients occupy (your capacity).",
        "es": "Los recursos son quienes ejecutan tu servicio (tus colaboradores), o en su defecto, el lugar que tus clientes ocupan (tu capacidad).",
        "pt": "Os recursos são aqueles que realizam o seu serviço (seus colaboradores), ou alternativamente, o lugar que seus clientes ocupam (sua capacidade)."
      },
      "Add new Resource": {
        "en": "Add new Resource",
        "es": "Añadir nuevo recurso",
        "pt": "Adicionar novo recurso"
      },
      "Continue": {
        "en": "Continue",
        "es": "Continuar",
        "pt": "Continuar"
      },
      "Validarion": {
        "en": "To continue, you must add at least one resource.",
        "es": "Para continuar debes añadir al menos un recurso.",
        "pt": "Para continuar, você deve adicionar pelo menos um recurso."
      },
      "Save": {
        "en": "Save",
        "es": "Guardar",
        "pt": "Salvar"
      },
      "Delete": {
        "en": "Delete",
        "es": "Borrar",
        "pt": "excluir"
      },
    },
    "New resource": {
      "Add resource": {
        "en": "Add resource",
        "es": "Añadir recurso",
        "pt": "Adicionar recurso"
      },
      "Description": {
        "en": "Add the basic information of your resource. You will be able to modify this information later.",
        "es": "Añade la información básica de tu recurso, esta información la podrás modificar más adelante.",
        "pt": "Adicione as informações básicas do seu recurso. Você poderá modificar essas informações mais tarde."
      },
      "Name": {
        "en": "Name",
        "es": "Nombre",
        "pt": "Nome"
      },
      "Description Title": {
        "en": "Description",
        "es": "Descripción",
        "pt": "Descrição"
      },
      "Service association": {
        "en": "Which services does this resource offer?",
        "es": "¿Qué servicios ofrece este recurso?",
        "pt": "Quais serviços esse recurso oferece?"
      },
      "Save": {
        "en": "Save",
        "es": "Guardar",
        "pt": "Salvar"
      },
      "fields": {
        "en": "These fields are mandatory",
        "es": "Estos campos son obligatorios",
        "pt": "Esses campos são obrigatórios"
      }
    },
    "Business Hours": {
      "en": "Business Hours",
      "es": "Horarios de atención",
      "pt": "Horário de atendimento",
      "Description": {
        "en": "When can your clients book an appointment?",
        "es": "¿Cuándo pueden tus clientes reservar una cita?",
        "pt": "Quando seus clientes podem agendar uma consulta?"
      },
      "Sunday": {
        "en": "Sunday",
        "es": "Domingo",
        "pt": "Domingo"
      },
      "Monday": {
        "en": "Monday",
        "es": "Lunes",
        "pt": "Segunda-feira"
      },
      "Tuesday": {
        "en": "Tuesday",
        "es": "Martes",
        "pt": "Terça-feira"
      },
      "Wednesday": {
        "en": "Wednesday",
        "es": "Miércoles",
        "pt": "Quarta-feira"
      },
      "Thursday": {
        "en": "Thursday",
        "es": "Jueves",
        "pt": "Quinta-feira"
      },
      "Friday": {
        "en": "Friday",
        "es": "Viernes",
        "pt": "Sexta-feira"
      },
      "Saturday": {
        "en": "Saturday",
        "es": "Sábado",
        "pt": "Sábado"
      },
      "Continue": {
        "en": "Continue",
        "es": "Continuar",
        "pt": "Continuar"
      }
    },
    "Copy schedule": {
      "en": "Copy schedule",
      "es": "Copiar horario",
      "pt": "Copiar agenda",
      "Sunday": {
        "en": "Sunday",
        "es": "Domingo",
        "pt": "Domingo"
      },
      "Monday": {
        "en": "Monday",
        "es": "Lunes",
        "pt": "Segunda-feira"
      },
      "Tuesday": {
        "en": "Tuesday",
        "es": "Martes",
        "pt": "Terça-feira"
      },
      "Wednesday": {
        "en": "Wednesday",
        "es": "Miércoles",
        "pt": "Quarta-feira"
      },
      "Thursday": {
        "en": "Thursday",
        "es": "Jueves",
        "pt": "Quinta-feira"
      },
      "Friday": {
        "en": "Friday",
        "es": "Viernes",
        "pt": "Sexta-feira"
      },
      "Saturday": {
        "en": "Saturday",
        "es": "Sábado",
        "pt": "Sábado"
      },
      "Continue": {
        "en": "Continue",
        "es": "Continuar",
        "pt": "Continuar"
      }
    }
  },
  "Demo": {
    "CTA": {
      "en": "CTA",
      "es": "CTA",
      "pt": "CTA",
      "Logout": {
        "en": "Logout",
        "es": "Salir",
        "pt": "Sair"
      },
      "Upgrade": {
        "en": "Upgrade",
        "es": "Actualizar Plan",
        "pt": "Atualizar Plano"
      }
    },
    "Chat": {
      "en": "Chat",
      "es": "Chat",
      "pt": "Chat",
      "Placeholder": {
        "en": "Write a message here",
        "es": "Escribe un mensaje aquí",
        "pt": "Escreva uma mensagem aqui"
      },
      "New Chat": {
        "en": "New chat",
        "es": "Nuevo chat",
        "pt": "Novo chat"
      },
      "New chat with uuid": {
        "en": "New chat with uuid",
        "es": "Nuevo chat con uuid",
        "pt": "Novo chat com uuid"
      },
      "New chat with": {
        "en": "Chat with ",
        "es": "Chat con ",
        "pt": "Chat com "
      },
      "New conversation": {
        "en": "New conversation",
        "es": "Nueva conversación",
        "pt": "Nova conversa"
      }
    },
    "Configuration": {
      "en": "Configuration",
      "es": "Configuración",
      "pt": "Configuração",
      "Commerce": {
        "en": "Commerce",
        "es": "Comercio",
        "pt": "Comércio"
      },
      "Name": {
        "en": "Commerce",
        "es": "Comercio",
        "pt": "Comércio"
      },
      "Industry": {
        "en": "Industry",
        "es": "Industria",
        "pt": "Indústria"
      },
      "Business": {
        "en": "Business",
        "es": "Negocio",
        "pt": "Negócio"
      },
      "Button": {
        "en": "Save",
        "es": "Guardar",
        "pt": "Salvar"
      },
      "Edit Business Hours": {
        "en": "Edit Business Hours",
        "es": "Editar Horarios de atención",
        "pt": "Editar Horário de atendimento"
      },
      "Services and Pricing": {
        "en": "Services and Pricing",
        "es": "Servicios y Precios",
        "pt": "Serviços e Preços"
      },
      "Team and Availability": {
        "en": "Team and Availability",
        "es": "Equipo y Disponibilidad",
        "pt": "Equipe e Disponibilidade"
      },
      "Choose Services": {
        "en": "Choose the Services",
        "es": "Elige los servicios",
        "pt": "Escolha os serviços"
      },
      "Continue": {
        "en": "Continue",
        "es": "Continuar",
        "pt": "Continuar"
      },
    }
  }
} as const;
