import { useState } from "react";
import { CommerceConfig } from "./commerce/commerce";
import { ServicesAndPrices } from "./services-and-prices/services-and-prices";
import { TeamAndAvailability } from "./team-and-availability/team-and-availability";
import { People, ShoppingBag, Storefront } from "@mui/icons-material"
import { useLangParam } from "../../../../core/hooks";
import { translations } from "../../../../core/constants/translations";
import { UpgradeBtn } from "../../../../components/buttons/upgrade.btn";
import { LogoutBtn } from "../../../../components/buttons/logout.btn";
import { SignInBtn } from "../../../../components/buttons/sign-in.btn";
import { SignUpBtn } from "../../../../components/buttons/sign-up.btn";
import { useSelector } from "react-redux";
import { selectUserId } from "../../../../store/user/selectors";

export const Configuration = () => {
  const userId = useSelector(selectUserId);

  const [state, setState] = useState<"commerce" | "services" | "resources">();
  const lang = useLangParam();

  const translation = translations["Demo"]["Configuration"];

  return (
    <div className="select-none grid gap-4">
      <div className="flex flex-col px-6 pt-8 pb-4 w-full">
        <p className="text-2xl font-semibold">{translation[lang]}</p>
      </div>

      {[
        {
          key: "commerce",
          title: translation["Commerce"][lang],
          description: "Your commerce details and configurations",
          icon: <Storefront />,
          dropdown: <CommerceConfig onClose={() => setState(undefined)} />,
        },
        {
          key: "services",
          title: translation["Services and Pricing"][lang],
          description:
            "The services that your commerce provides and their prices",
          icon: <ShoppingBag />,
          dropdown: <ServicesAndPrices />,
        },
        {
          key: "resources",
          title: translation["Team and Availability"][lang],
          description:
            "When are your team members available and their schedule",
          icon: <People />,
          dropdown: <TeamAndAvailability />,
        },
      ].map((data) => {
        return (
          <ConfigItem
            isOpen={state === data.key}
            onClick={() => {
              if (state === data.key) setState(undefined);
              else setState(data.key as any);
            }}
            {...data}
          />
        );
      })}

      <div className="lg:hidden p-4 flex gap-2 items-center justify-between text-lg font-medium">
        <UpgradeBtn />
        {userId ? <LogoutBtn /> : (<><SignInBtn /> <SignUpBtn /></>) }
      </div>
    </div>
  );
};

const ConfigItem = ({
  title,
  icon,
  dropdown,
  isOpen,
  onClick,
}: {
  title: string;
  description: string;
  icon: JSX.Element;
  dropdown: JSX.Element;
  isOpen: boolean;
  onClick: () => void;
}) => {
  return (
    <div>
      <div
        className="mx-2 p-4 flex flex-col align-middle hover:bg-white cursor-pointer bg-white/50 rounded-2xl"
        onClick={onClick}
      >
        <div className="text-lg font-medium flex items-center">
          <div className="border-2 border-indigo-200 rounded-full w-14 h-14 flex justify-center items-center mr-4">{icon}</div>
          {title}
        </div>
      </div>

      {isOpen && dropdown}
    </div>
  );
};
