import { CheckOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../../components/modal/modal";
import { translations } from "../../../../../core/constants/translations";
import { useLangParam } from "../../../../../core/hooks";
import { selectCapabilities } from "../../../../../store/capabilities/selectors";
import {
  addCapabilitiesThunk,
  removeCapabilityThunk,
} from "../../../../../store/capabilities/thunks";
import { selectCommerceId } from "../../../../../store/commerce/selectors";
import { ServiceInterface } from "../../../../../store/service/interfaces";
import { selectServices } from "../../../../../store/service/selectors";
import { AppDispatch } from "../../../../../store/store";

export const ChooseServicesModal = ({
  onClose,
  resourceId,
}: {
  onClose: () => void;
  resourceId: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam();

  const translation = translations["Demo"]["Configuration"];

  const services = useSelector(selectServices);
  const capabilities = useSelector(selectCapabilities);
  const resourceCapabilities = capabilities.filter(({ resource_id }) => {
    return resource_id === resourceId;
  });
  const commerceId = useSelector(selectCommerceId);

  const [checks, setChecks] = useState<string[]>(resourceCapabilities.map(({ service_id }) => service_id));

  return (
    <Modal title={translation["Choose Services"][lang]} onClose={onClose}>
      <div className="px-5 py-2 flex flex-col items-center">
        {services.map((service, i, list) => {
          return (
            <ServiceItem
              service={service}
              index={i}
              last={list.length - 1 === i}
              checked={checks.includes(service.id)}
              onClick={() => {
                setChecks((checks) => {
                  if (!checks.includes(service.id)) {
                    return [...checks, service.id]
                  } else {
                    return checks.filter((id) => service.id !== id)
                  }
                });
              }}
            />
          );
        })}

        <Button
          variant="contained"
          sx={{
            width: "250px",
            bg: "grey",
            mt: "10px",
            mb: "15px",
            background: "oklch(30% 0 0)",
            "&:hover": {
              background: "oklch(40% 0 0)",
            },
          }}
          onClick={async () => {
            await Promise.all([
              ...resourceCapabilities.map(async ({ id }) => {
                await dispatch(removeCapabilityThunk(id));
              }),
              ...checks.map(async (id) => {
                commerceId &&
                  await dispatch(
                    addCapabilitiesThunk({
                      commerce_id: commerceId,
                      resource_id: resourceId,
                      service_id: id,
                    }),
                  );
              })
            ])

            onClose();
          }}
        >
          {translation["Continue"][lang]}
        </Button>
      </div>
    </Modal>
  );
};

const ServiceItem = ({
  service,
  last,
  index,
  checked,
  onClick,
}: {
  service: ServiceInterface;
  last: boolean;
  index: number;
  checked: boolean;
  onClick: () => void;
}) => {
  const border = "border-b border-gray-300";

  return (
    <div
      className={`grid grid-cols-[25px_1fr] items-center h-12 gap-3 w-full items-center ${!last ? border : ""}`}
    >
      <div
        className={`
            flex items-center justify-center
            ${checked ? "" : "border border-gray-300"}
            rounded-md
            ${checked ? "bg-indigo-400" : ""}
            w-5 h-5 mb-1
            ${checked ? "hover:bg-indigo-200" : "hover:bg-gray-100"}
            cursor-pointer
          `}
        onClick={onClick}
      >
        {checked ? (
          <CheckOutlined
            sx={{
              color: "white",
              height: "1.25rem",
              width: "1.25rem",
            }}
          />
        ) : (
          <></>
        )}
      </div>

      <div className="flex items-center w-full justify-between">
        <p className="w-32">{service.name ?? `Service #${index + 1}`}</p>

        <p className="text-xs text-gray-500">{service.duration} min</p>

        <p className="font-medium">
          {service.price} {service.currency}
        </p>
      </div>
    </div>
  );
};
