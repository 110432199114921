import { useContext } from "react";
import { ResourceContext } from "../resource-page";
import { Day } from "../../../core/interfaces";
import { capitalize } from "../../../core/utils/string-utils";
import { ResourceTimeframeInterface } from "../../../store/resource_timeframe/interfaces";
import { WeekScheduleTable } from "../../../components/week-schedule/week-schedule-table";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { addResourceTimeframesThunk, removeResourceTimeframesThunk, updateResourceTimeframesThunk } from "../../../store/resource_timeframe/thunks";

export const ResourceSchedule = () => {
  const dispatch = useDispatch<AppDispatch>();
  const ctx = useContext(ResourceContext);
  const timeframes = ctx.resourceTimeframes.filter((timeframe) => {
    return ctx.currentResource?.id.includes(timeframe.resource_id);
  });

  return (
    <div className={`flex flex-col bg-white/50 rounded-2xl`}>
      <div className="border-b border-b-1 p-5">
        <p className="text-slate-700 text-xl font-medium">
          Schedule of your resource
        </p>

        <p className="text-slate-500 text-sm mt-2">
          Add a schedule to the resource
        </p>
      </div>

      <div className="lg:hidden">
        <WeekScheduleTable
          timeframes={timeframes}
          onAddTimeframe={(t) => {
            ctx.currentResource?.fmap((resource) => {
              dispatch(
                addResourceTimeframesThunk({
                  ...t,
                  resource_id: resource.id,
                }),
              );
            });
          }}
          onRemoveTimeframes={(ids) => {
            dispatch(removeResourceTimeframesThunk(ids));
          }}
          onUpdateTimeframes={(t) => {
            dispatch(updateResourceTimeframesThunk(t));
          }}
        />
      </div>

      <div className="max-lg:hidden">
        <ResourceScheduleTable />
      </div>
    </div>
  );
}

const ResourceScheduleTable = () => {
  const ctx = useContext(ResourceContext);

  return (
    <div className="flex flex-col lg:flex-row justify-between p-5 max-lg:gap-6">
      {Object.entries(Day).map(([_, value]) => {
        const timeframes = ctx.resourceTimeframes.filter((timeframe) => {
          return timeframe.day === value;
        }).filter((timeframe) => {
          return ctx.currentResource?.id.includes(timeframe.resource_id);
        });

        const totalDuration = timeframes.reduce((acc, { duration }) => acc + (duration || 0), 0);
        const duration = `${Math.floor(totalDuration / 60).toString().padStart(2, "0")}:${(totalDuration % 60).toString().padStart(2, "0")}`;

        return (
          <div className="flex lg:flex-col max-lg:justify-between max-lg:pr-7">
            <div className="w-32">
              <p className="text-slate-700 text-center font-semibold">
                {capitalize(value)}
              </p>

              {!!totalDuration &&
                <p className="text-slate-700 text-center">
                  {duration}h
                </p>
              }
            </div>

            <div className="flex flex-col gap-2">
              {timeframes?.map(TimeframeItem(() => {
                ctx.setEditTimeframesModal(true);
              }))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const TimeframeItem = (openModal: () => void) => (timeframe: ResourceTimeframeInterface) => {
  const start = timeframe.start?.slice(0, 5);
  const time = start?.split(":");

  if (!time) return null;

  const hour = time[0];
  const minute = time[1];

  const mend = parseInt(hour) * 60 + parseInt(minute) + (timeframe.duration || 0);
  const end = `${Math.floor(mend / 60).toString().padStart(2, "0")}:${(mend % 60).toString().padStart(2, "0")}`;

  return (
    <div
      className="flex items-center justify-center bg-indigo-200 rounded-lg h-12 w-28 cursor-pointer"
      onClick={openModal}
    >
      <p className="text-slate-700">
        {start} - {end}
      </p> 
    </div>
  );
}
