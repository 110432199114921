import { ChevronLeft } from "@mui/icons-material";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PlatformButton } from "../../../components/buttons/button";
import { WeekScheduleModal } from "../../../components/week-schedule/week-schedule-modal";
import { addCapabilitiesThunk, removeCapabilityThunk } from "../../../store/capabilities/thunks";
import { selectCommerceId } from "../../../store/commerce/selectors";
import { updateResourceThunk, addResourceThunk } from "../../../store/resource/thunks";
import { selectResourceTimeframes } from "../../../store/resource_timeframe/selectors";
import { addResourceTimeframesThunk, removeResourceTimeframesThunk, updateResourceTimeframesThunk } from "../../../store/resource_timeframe/thunks";
import { AppDispatch } from "../../../store/store";
import { ResourceContext } from "../resource-page";
import { ResourceCapabilities } from "./resource-capabilities";
import { ResourceInformation } from "./resource-information";
import { ResourceSchedule } from "./resource-schedule";

export const EditResource = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const ctx = useContext(ResourceContext);
  const resourceTimeframes = useSelector(selectResourceTimeframes);
  const commerceId = useSelector(selectCommerceId);
  const timeframes = resourceTimeframes.filter(({ resource_id }) => {
    return ctx.currentResource?.id.includes(resource_id);
  });

  return (
    <div className={`gap-5 pt-5 lg:gap-10 lg:pt-10 flex flex-col h-[calc(100%-60px)]`}>
      <div className={`flex items-center gap-5 h-16 w-full bg-white/50 rounded-2xl p-3 lg:p-5`}>
        <div
          className="w-[25px] lg:w-[25px] h-[25px] lg:h-[25px] hover:bg-gray-300/50 cursor-pointer rounded-full"
          onClick={() => {
            ctx.setCurrentResource(undefined);
            navigate(-1);
          }}
        >
          <ChevronLeft
            style={{
              width: "100%",
              height: "100%",
              color: "rgb(51 65 85)",
            }}
          />
        </div>

        <p className={`text-slate-700 text-xl lg:text-2xl font-medium`}>
          Edit Resource
        </p>

        <PlatformButton
          title="Save"
          className={`bg-indigo-500 hover:bg-indigo-400 w-20`}
          onClick={async () => {
            if (ctx.currentResource?.id.at(0) === "") {
              const resource = await Promise.all(ctx.currentResource?.fmap(async (resource) => {
                return await dispatch(
                  addResourceThunk({
                    name: resource.name,
                    description: resource.description,
                    commerce_id: resource.commerce_id,
                  }),
                ).unwrap();
              }) || [])

              await Promise.all(ctx.capabilities.map(async (service_id) => {
                console.log("UPDATE RESOURCE CAPABILITY", service_id);
                
                const resource_id = resource.at(0)?.id;

                commerceId && resource_id && await dispatch(addCapabilitiesThunk({
                  resource_id,
                  service_id,
                  commerce_id: commerceId,
                }));
              }))
            } else {
              await Promise.all(ctx.currentResource?.fmap(async (resource) => {
                console.log("UPDATE RESOURCE", resource);

                await dispatch(
                  updateResourceThunk({
                    ...resource,
                  }),
                );
              }) || [])

              ctx.currentResource?.capability.id.map(async (id) => {
                await dispatch(removeCapabilityThunk(id));
              })

              ctx.capabilities.forEach((service_id) => {
                console.log("UPDATE RESOURCE CAPABILITY", service_id);

                ctx.currentResource?.id.map(async (resource_id) => {
                  commerceId && await dispatch(addCapabilitiesThunk({
                    resource_id,
                    service_id,
                    commerce_id: commerceId,
                  }));
                })
              })
            }

            ctx.setCurrentResource(undefined);
            ctx.setCreateResource(false);
            navigate("/resource");
          }}
        />
      </div>

      {ctx.editTimeframesModal &&
        <WeekScheduleModal
          onClose={() => {
            ctx.setEditTimeframesModal(false);
          }}
          timeframes={timeframes}
          onAddTimeframe={(t) => {
            ctx.currentResource?.fmap((resource) => {
              dispatch(
                addResourceTimeframesThunk({
                  ...t,
                  resource_id: resource.id,
                }),
              );
            });
          }}
          onRemoveTimeframes={(ids) => {
            dispatch(removeResourceTimeframesThunk(ids));
          }}
          onUpdateTimeframes={(t) => {
            dispatch(updateResourceTimeframesThunk(t));
          }}
        />
      }

      <div className="flex flex-col lg:flex-row gap-5 lg:gap-10 w-full overflow-y-scroll scrollbar scrollbar-thumb-rounded-full scrollbar-thumb-indigo-400/25 scrollbar-w-1.5 pr-2">
        <ResourceCapabilities />

        <div className={`flex flex-col gap-5 lg:gap-10 w-full`}>
          <ResourceInformation />  
            
          <ResourceSchedule />
        </div>
      </div>
    </div>
  );
}
