import { Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrencySelector } from "../../../../../components/currency-selector/currency-selector";
import { Modal } from "../../../../../components/modal/modal";
import { countries } from "../../../../../core/constants/countries";
import { serviceColors } from "../../../../../core/constants/service-colors";
import { translations } from "../../../../../core/constants/translations";
import { useLangParam } from "../../../../../core/hooks";
import { selectCommerce, selectCommerceId } from "../../../../../store/commerce/selectors";
import { addServiceThunk } from "../../../../../store/service/thunks";
import { AppDispatch } from "../../../../../store/store";

export const AddServiceModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const lang = useLangParam()

  const translation = translations["Onboarding"]["New service"]

  const [loading, setLoading] = useState(false);

  const commerceId = useSelector(selectCommerceId);
  const commerce = useSelector(selectCommerce);

  const countryCurrency = countries.find(({ name }) => {
    return name === commerce?.country;
  })?.currencyCode;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [hour, setHour] = useState<number>();
  const [minute, setMinute] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [currency, setCurrency] = useState(countryCurrency ?? "");

  return (
    <Modal
      title={translation["Add service"][lang]}
      onClose={onClose}
    >
      <div className="w-full p-8 flex flex-col gap-2">
        <TextField
          label={translation["Name"][lang] + " *"}
          size="small"
          sx={{
            width: "100%",
          }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <TextField
          multiline
          label={translation["Description Title"][lang] + " *"}
          size="small"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />

        <div>
          <p className="text-xs text-gray-600 mb-2">{translation["Service duration"][lang]} *</p>

          <div className="flex gap-2">
            <TextField
              label={translation["Hours"][lang]}
              type="number"
              size="small"
              value={hour}
              onChange={(e) => {
                setHour(+e.target.value);
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                }
              }}
            />

            <TextField
              label={translation["Minutes"][lang]}
              type="number"
              size="small"
              value={minute}
              onChange={(e) => {
                setMinute(+e.target.value);
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                }
              }}
            />
          </div>
        </div>

        <TextField
          label={translation["Price"][lang] + " *"}
          type="number"
          size="small"
          value={price}
          onChange={(e) => {
            setPrice(+e.target.value);
          }}
          InputProps={{
            inputProps: {
              min: 0,
            }
          }}
        />

        <CurrencySelector
          placeholder={translation["Currency"][lang]}
          currency={currency}
          setCurrency={(value) => {
            setCurrency(value);
          }}  
        />

        <p className="text-xs text-gray-600 py-1">
          * {translation["fields"][lang]}
        </p>

        <Button
          variant="contained"
          sx={{
            width: "100%",
            background: "oklch(30% 0 0)",
            "&:hover": {
              background: "oklch(40% 0 0)",
            },
          }}
          disabled={
            !name || !description || (!hour && !minute) || !price || !currency || loading || price < 0 || (hour || 0) < 0 || (minute || 0) < 0
          }
          onClick={() => {
            setLoading(true);

            commerceId &&
              dispatch(
                addServiceThunk({
                  commerce_id: commerceId,
                  name,
                  description,
                  duration: (hour ?? 0) * 60 + (minute ?? 0) || undefined,
                  price,
                  currency,
                  color:
                    serviceColors[
                      Math.floor(Math.random() * serviceColors.length)
                    ],
                }),
              )
                .unwrap()
                .then(() => {
                  onClose()
                  setLoading(false);
                });
          }}
        >
          {loading ?
            <CircularProgress
              size={25}
              sx={{
                color: "rgb(192 132 252)",
              }}
            />
          : translation["Save"][lang]}
        </Button>
      </div>
    </Modal>
  );
};
