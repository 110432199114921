import { createSelector } from "@reduxjs/toolkit";
import { Entity } from "../../core/repository/repository";
import { Resource } from "../resource/selectors";
import { Service } from "../service/selectors";
import { RootState, selectState } from "../store";
import { CapabilityInterface } from "./interfaces";

export const selectCapabilitiesState = createSelector(
  [selectState],
  (state) => state.capabilities,
);

export const selectCapabilitiesStatus = createSelector(
  [selectCapabilitiesState],
  (state) => state.status,
);

export const selectCapabilities = createSelector(
  [selectCapabilitiesState],
  (state) => state.value,
);

export const selectCapabilityRepository = createSelector(
  [selectCapabilitiesState],
  (state) => new Entity(state.value),
);

export class Capability {
  private data: CapabilityInterface[];

  constructor(private state: RootState) {
    this.data = state.capabilities.value;
  }

  static from(data: CapabilityInterface[], state: RootState) {
    const capability = new Capability(state);
    capability.data = data;

    return capability;
  }

  fmap<OUT>(f: (v: CapabilityInterface, i: number, list: CapabilityInterface[]) => OUT): OUT[] {
    return this.data.map(f);
  }

  map<OUT>(f: (v: Capability, i: number, list: Capability) => OUT): OUT[] {
    return this.data.map((v, i) => f(Capability.from([v], this.state), i, Capability.from(this.data, this.state)));
  }

  get resource() {
    const resources = this.state.resources.value;

    const filteredResources = resources.filter(({ id }) => this.data.map(({ resource_id }) => resource_id).includes(id));

    return Resource.from(filteredResources, this.state);
  }

  get service() {
    const services = this.state.services.value;

    const filteredServices = services.filter(({ id }) => this.data.map(({ service_id }) => service_id).includes(id));

    return Service.from(filteredServices, this.state);
  }

  get id() {
    return this.data.map(({ id }) => id);
  }
}
